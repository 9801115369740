.prizm-font-title-h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.prizm-font-main-body-14,
.prizm-font-table-text-14 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.prizm-font-input-text-14px {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.prizm-font-main-body-12 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.prizm-font-btn-links-14 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}
.prizm-font-dynamic-title-h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}
.prizm-font-input-text-helper {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}
body {
  font-family: 'Inter';
}
/*1-9*/
/*10-19*/
/*20-29*/
/*30-39*/
/*40-49*/
/*50-59*/
/*60-69*/
/*70-79*/
/*80-89*/
/*90-99*/
/*100 >*/
:root {
  --prizm-palette-g1: #ffffff;
  --prizm-palette-g2: #f4f6fb;
  --prizm-palette-g3: #d6dbe7;
  --prizm-palette-g4: #bfc6d7;
  --prizm-palette-g5: #a1a5b7;
  --prizm-palette-g6: #8d91a4;
  --prizm-palette-g7: #777b92;
  --prizm-palette-g8: #606580;
  --prizm-palette-g9: #50546b;
  --prizm-palette-g10: #404355;
  --prizm-palette-g11: #303340;
  --prizm-palette-g12: #20222b;
  --prizm-palette-g13: #13151c;
  --prizm-palette-b1: #337eff;
  --prizm-palette-b2: #0f62fe;
  --prizm-palette-b3: #003ed6;
  --prizm-palette-y1: #ff7c0a;
  --prizm-palette-y2: #d36300;
  --prizm-palette-y3: #d36300;
  --prizm-palette-r1: #f14141;
  --prizm-palette-r2: #da181e;
  --prizm-palette-r3: #ad1218;
  --prizm-palette-gr1: #49ab4d;
  --prizm-palette-gr2: #0c7733;
  --prizm-palette-p1: #a2e2ff;
  --prizm-palette-b1-20: #d6e5ff;
  --prizm-palette-y1-20: #ffe5ce;
  --prizm-palette-r1-20: #fcd9d9;
  --prizm-palette-gr1-20: #dbeedb;
  --prizm-palette-g7-20: #e4e5e9;
  --prizm-palette-b1-30: #1d3460;
  --prizm-palette-y1-30: #5a3417;
  --prizm-palette-r1-30: #562227;
  --prizm-palette-gr1-30: #23422b;
  --prizm-palette-g7-30: #31343f;
  --prizm-palette-lock-l: #99bfff;
  --prizm-palette-lock-d: #295095;
  --prizm-pallete-shadow-light: rgba(191, 198, 215, 0.5);
  --prizm-pallete-shadow-dark: rgba(0, 0, 0, 0.5);
}
:root,
*[data-prizm-theme='light'] {
  /* Color styles */
  --prizm-demo: var(--prizm-palette-g1);
  /* Focused */
  --prizm-focus-border: var(--prizm-palette-b1-20);
  /* Shadow  */
  --prizm-shadow: var(--prizm-pallete-shadow-light);
  --prizm-bg: var(--prizm-exceptions-white);
  /* Text */
  --prizm-text-contrast: var(--prizm-palette-g12);
  --prizm-text-subscript: var(--prizm-palette-g7);
  --prizm-text-main: var(--prizm-palette-g9);
  /* Background */
  --prizm-bg-dashboard-forms: var(--prizm-palette-g2);
  --prizm-bg-body: var(--prizm-palette-g1);
  --prizm-bg-header: var(--prizm-palette-g1);
  /* Border */
  --prizm-border-forms: var(--prizm-palette-g4);
  --prizm-border-icon: var(--prizm-palette-g7);
  --prizm-border-widget: var(--prizm-palette-g3);
  /* Index */
  --prizm-index-fact: var(--prizm-palette-g5);
  --prizm-index-prediction: var(--prizm-palette-p1);
  --prizm-index-plan: var(--prizm-palette-b1);
  --prizm-index-good: var(--prizm-palette-gr1);
  --prizm-index-warning: var(--prizm-palette-y1);
  --prizm-index-danger: var(--prizm-palette-r1);
  --prizm-index-lock: var(--prizm-palette-lock-l);
  /* Lite */
  --prizm-lite-g730-20: var(--prizm-palette-g7-20);
  --prizm-lite-b130-20: var(--prizm-palette-b1-20);
  --prizm-lite-gr130-20: var(--prizm-palette-gr1-20);
  --prizm-lite-y130-20: var(--prizm-palette-y1-20);
  --prizm-lite-r130-20: var(--prizm-palette-r1-20);
  --prizm-g2-g11: var(--prizm-palette-g2);
  --prizm-g3-g11: var(--prizm-palette-g3);
  /* Grey */
  --prizm-grey-g13-g1: var(--prizm-palette-g1);
  --prizm-grey-g12-g2: var(--prizm-palette-g2);
  --prizm-grey-g11-g3: var(--prizm-palette-g3);
  --prizm-grey-g10-g4: var(--prizm-palette-g4);
  --prizm-grey-g9-g5: var(--prizm-palette-g5);
  --prizm-grey-g8-g6: var(--prizm-palette-g6);
  --prizm-grey-g7-g7: var(--prizm-palette-g7);
  --prizm-grey-g6-g8: var(--prizm-palette-g8);
  --prizm-grey-g5-g9: var(--prizm-palette-g9);
  --prizm-grey-g4-g10: var(--prizm-palette-g10);
  --prizm-grey-g3-g11: var(--prizm-palette-g11);
  --prizm-grey-g2-g12: var(--prizm-palette-g12);
  --prizm-grey-g1-g13: var(--prizm-palette-g13);
  /* Exeptions */
  --prizm-exceptions-white: var(--prizm-palette-g1);
  --prizm-link: var(--prizm-palette-b1);
  --prizm-exeptions-g1-b1: var(--prizm-palette-b1);
  --prizm-disabled-outline: var(--prizm-grey-g12-g2);
  --prizm-primary: var(--prizm-index-plan);
  --prizm-primary-pressed: var(--prizm-palette-b3);
  --prizm-primary-hover: var(--prizm-palette-b2);
  --prizm-primary-ghost-hover: var(--prizm-grey-g12-g2);
  --prizm-primary-ghost-pressed: var(--prizm-lite-b130-20);
  --prizm-warning-pressed: var(--prizm-palette-y3);
  --prizm-danger-pressed: var(--prizm-palette-r3);
  --prizm-disabled: var(--prizm-grey-g12-g2);
  --prizm-disabled-text: var(--prizm-grey-g10-g4);
  --prizm-secondary: var(--prizm-grey-g8-g6);
  --prizm-secondary-pressed: var(--prizm-grey-g6-g8);
  --prizm-secondary-hover: var(--prizm-grey-g7-g7);
  --prizm-danger-ghost-hover: var(--prizm-grey-g12-g2);
  --prizm-success-ghost-hover: var(--prizm-grey-g12-g2);
  --prizm-success-ghost-pressed: var(--prizm-lite-gr130-20);
  --prizm-warning-ghost-hover: var(--prizm-grey-g12-g2);
  --prizm-disabled-ghost-hover: transparent;
  --prizm-disabled-ghost-text: var(--prizm-grey-g10-g4);
  --prizm-disabled-ghost-pressed: var(--prizm-grey-g12-g2);
  --prizm-danger-ghost-pressed: var(--prizm-lite-r130-20);
  --prizm-warning-ghost-pressed: var(--prizm-lite-y130-20);
  --prizm-secondary-ghost-hover: var(--prizm-grey-g12-g2);
  --prizm-secondary-ghost-pressed: var(--prizm-lite-b130-20);
}
*[data-prizm-theme='dark'] {
  /* Color styles */
  --prizm-demo: var(--prizm-palette-g12);
  /* Shadow  */
  --prizm-shadow: var(--prizm-pallete-shadow-dark);
  --prizm-bg: var(--prizm-palette-g13);
  --prizm-g2-g11: var(--prizm-palette-g11);
  --prizm-g3-g11: var(--prizm-palette-g11);
  /* Text */
  --prizm-text-contrast: var(--prizm-palette-g2);
  --prizm-text-subscript: var(--prizm-palette-g7);
  --prizm-text-main: var(--prizm-palette-g5);
  /* Background */
  --prizm-bg-dashboard-forms: var(--prizm-palette-g13);
  --prizm-bg-body: var(--prizm-palette-g12);
  --prizm-bg-header: var(--prizm-palette-g11);
  /* Border */
  --prizm-border-forms: var(--prizm-palette-g9);
  --prizm-border-icon: var(--prizm-palette-g7);
  --prizm-border-widget: var(--prizm-palette-g11);
  /* Index */
  --prizm-index-fact: var(--prizm-palette-g1);
  --prizm-index-prediction: var(--prizm-palette-p1);
  --prizm-index-plan: var(--prizm-palette-b1);
  --prizm-index-good: var(--prizm-palette-gr1);
  --prizm-index-warning: var(--prizm-palette-y1);
  --prizm-index-danger: var(--prizm-palette-r1);
  --prizm-index-lock: var(--prizm-palette-lock-d);
  /* Lite */
  --prizm-lite-g730-20: var(--prizm-palette-g7-30);
  --prizm-lite-b130-20: var(--prizm-palette-b1-30);
  --prizm-lite-gr130-20: var(--prizm-palette-gr1-30);
  --prizm-lite-y130-20: var(--prizm-palette-y1-30);
  --prizm-lite-r130-20: var(--prizm-palette-r1-30);
  /* Grey */
  --prizm-grey-g13-g1: var(--prizm-palette-g13);
  --prizm-grey-g12-g2: var(--prizm-palette-g12);
  --prizm-grey-g11-g3: var(--prizm-palette-g11);
  --prizm-grey-g10-g4: var(--prizm-palette-g10);
  --prizm-grey-g9-g5: var(--prizm-palette-g9);
  --prizm-grey-g8-g6: var(--prizm-palette-g8);
  --prizm-grey-g7-g7: var(--prizm-palette-g7);
  --prizm-grey-g6-g8: var(--prizm-palette-g6);
  --prizm-grey-g5-g9: var(--prizm-palette-g5);
  --prizm-grey-g4-g10: var(--prizm-palette-g4);
  --prizm-grey-g3-g11: var(--prizm-palette-g3);
  --prizm-grey-g2-g12: var(--prizm-palette-g2);
  --prizm-grey-g1-g13: var(--prizm-palette-g1);
  /* Exeptions */
  --prizm-exceptions-white: var(--prizm-palette-g1);
  --prizm-link: var(--prizm-palette-lock-l);
  --prizm-exeptions-g1-b1: var(--prizm-palette-g1);
  --prizm-disabled: var(--prizm-grey-g11-g3);
  --prizm-disabled-outline: var(--prizm-grey-g11-g3);
  --prizm-disabled-text: var(--prizm-grey-g9-g5);
  --prizm-primary-ghost: transparent;
  --prizm-primary-ghost-hover: var(--prizm-palette-g11);
  --prizm-primary-ghost-pressed: var(--prizm-lite-b130-20);
  --prizm-secondary-ghost-hover: var(--prizm-palette-g11);
  --prizm-secondary-ghost-pressed: var(--prizm-lite-b130-20);
  --prizm-success-ghost-hover: var(--prizm-palette-g11);
  --prizm-success-ghost-pressed: var(--prizm-lite-gr130-20);
  --prizm-warning-ghost-hover: var(--prizm-palette-g11);
  --prizm-danger-ghost-hover: var(--prizm-palette-g11);
  --prizm-disabled-ghost-text: var(--prizm-grey-g9-g5);
  --prizm-disabled-ghost-hover: var(--prizm-palette-g11);
  --prizm-disabled-ghost-pressed: var(--prizm-grey-g11-g3);
}
/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
:root,
*[data-prizm-theme='light'] {
  --prizm-v2-palette-gray-base-0: #ffffff;
  --prizm-v2-palette-gray-base-25: #f9f9fb;
  --prizm-v2-palette-gray-base-100: #e6e8ef;
  --prizm-v2-palette-gray-base-200: #ced1df;
  --prizm-v2-palette-gray-base-300: #b7bbcd;
  --prizm-v2-palette-gray-base-400: #a0a5ba;
  --prizm-v2-palette-gray-base-500: #8b90a7;
  --prizm-v2-palette-gray-base-600: #73788e;
  --prizm-v2-palette-gray-base-700: #5c6075;
  --prizm-v2-palette-gray-base-800: #45495a;
  --prizm-v2-palette-gray-base-875: #2b2e3b;
  --prizm-v2-palette-gray-base-900: #2f323f;
  --prizm-v2-palette-gray-base-1000: #1a1c24;
  --prizm-v2-palette-gray-hover-50: #f2f3f6;
  --prizm-v2-palette-gray-hover-150: #dadde7;
  --prizm-v2-palette-gray-hover-250: #c3c7d7;
  --prizm-v2-palette-gray-hover-350: #aeb2c6;
  --prizm-v2-palette-gray-hover-450: #989db3;
  --prizm-v2-palette-gray-hover-550: #8489a1;
  --prizm-v2-palette-gray-hover-650: #787d94;
  --prizm-v2-palette-gray-hover-750: #62667c;
  --prizm-v2-palette-gray-hover-850: #4c5062;
  --prizm-v2-palette-gray-hover-950: #373a49;
  --prizm-v2-palette-gray-hover-1050: #23252f;
  --prizm-v2-palette-blue-100: #e7f1ff;
  --prizm-v2-palette-blue-200: #bad7ff;
  --prizm-v2-palette-blue-300: #8dbbff;
  --prizm-v2-palette-blue-400: #609dff;
  --prizm-v2-palette-blue-500: #337eff;
  --prizm-v2-palette-blue-600: #3870cc;
  --prizm-v2-palette-blue-700: #39609d;
  --prizm-v2-palette-blue-800: #354e73;
  --prizm-v2-palette-blue-900: #2d3a4e;
  --prizm-v2-palette-green-100: #eaf5e9;
  --prizm-v2-palette-green-200: #c1e2c1;
  --prizm-v2-palette-green-300: #99d09a;
  --prizm-v2-palette-green-400: #71be73;
  --prizm-v2-palette-green-500: #49ab4d;
  --prizm-v2-palette-green-600: #468a48;
  --prizm-v2-palette-green-700: #406b41;
  --prizm-v2-palette-green-800: #374f38;
  --prizm-v2-palette-green-900: #2c362c;
  --prizm-v2-palette-red-100: #fde8ea;
  --prizm-v2-palette-red-200: #fabec1;
  --prizm-v2-palette-red-300: #f79598;
  --prizm-v2-palette-red-400: #f46b6d;
  --prizm-v2-palette-red-500: #f14141;
  --prizm-v2-palette-red-600: #c14344;
  --prizm-v2-palette-red-700: #954243;
  --prizm-v2-palette-red-800: #6d3b3d;
  --prizm-v2-palette-red-900: #4a3132;
  --prizm-v2-palette-orange-100: #fff1e0;
  --prizm-v2-palette-orange-200: #ffd8a8;
  --prizm-v2-palette-orange-300: #ffc170;
  --prizm-v2-palette-orange-400: #ffab38;
  --prizm-v2-palette-orange-500: #ff9800;
  --prizm-v2-palette-orange-600: #c47c14;
  --prizm-v2-palette-orange-700: #916222;
  --prizm-v2-palette-orange-800: #654b27;
  --prizm-v2-palette-orange-900: #403526;
  --prizm-v2-palette-purple-100: #edecf7;
  --prizm-v2-palette-purple-200: #cac6ea;
  --prizm-v2-palette-purple-300: #a59ddb;
  --prizm-v2-palette-purple-400: #8578cd;
  --prizm-v2-palette-purple-500: #6353bf;
  --prizm-v2-palette-purple-600: #5a4e9c;
  --prizm-v2-palette-purple-700: #4e467b;
  --prizm-v2-palette-purple-800: #403c5c;
  --prizm-v2-palette-purple-900: #312f40;
  --prizm-v2-palette-teal-100: #e0f8fa;
  --prizm-v2-palette-teal-200: #a6eaf1;
  --prizm-v2-palette-teal-300: #71dbe9;
  --prizm-v2-palette-teal-400: #37cae1;
  --prizm-v2-palette-teal-500: #00badb;
  --prizm-v2-palette-teal-600: #0d92a9;
  --prizm-v2-palette-teal-700: #167382;
  --prizm-v2-palette-teal-800: #1b555e;
  --prizm-v2-palette-teal-900: #1a393d;
  --prizm-v2-palette-yellow-100: #fcf4e3;
  --prizm-v2-palette-yellow-200: #f4e0a8;
  --prizm-v2-palette-yellow-300: #efcf70;
  --prizm-v2-palette-yellow-400: #e7bf3a;
  --prizm-v2-palette-yellow-500: #e2b103;
  --prizm-v2-palette-yellow-600: #b18e10;
  --prizm-v2-palette-yellow-700: #896f1a;
  --prizm-v2-palette-yellow-800: #62521d;
  --prizm-v2-palette-yellow-900: #40371c;
  --prizm-v2-palette-pink-100: #fae9f5;
  --prizm-v2-palette-pink-200: #f2cae5;
  --prizm-v2-palette-pink-300: #eaa8d3;
  --prizm-v2-palette-pink-400: #e183bf;
  --prizm-v2-palette-pink-500: #da62ac;
  --prizm-v2-palette-pink-600: #b25c91;
  --prizm-v2-palette-pink-700: #8b5075;
  --prizm-v2-palette-pink-800: #68445b;
  --prizm-v2-palette-pink-900: #493642;
  --prizm-v2-gray-base-0: #ffffff;
  --prizm-v2-gray-base-100: #e6e8ef;
  --prizm-v2-gray-base-200: #ced1df;
  --prizm-v2-gray-base-300: #b7bbcd;
  --prizm-v2-gray-base-400: #a0a5ba;
  --prizm-v2-gray-base-500: #8b90a7;
  --prizm-v2-gray-base-600: #73788e;
  --prizm-v2-gray-base-700: #5c6075;
  --prizm-v2-gray-base-800: #45495a;
  --prizm-v2-gray-base-900: #2f323f;
  --prizm-v2-gray-base-1000: #1a1c24;
  --prizm-v2-gray-hover-50: #f2f3f6;
  --prizm-v2-gray-hover-150: #dadde7;
  --prizm-v2-gray-hover-250: #c3c7d7;
  --prizm-v2-gray-hover-350: #aeb2c6;
  --prizm-v2-gray-hover-450: #989db3;
  --prizm-v2-gray-hover-550: #8489a1;
  --prizm-v2-gray-hover-650: #787d94;
  --prizm-v2-gray-hover-750: #62667c;
  --prizm-v2-gray-hover-850: #4c5062;
  --prizm-v2-gray-hover-950: #373a49;
  --prizm-v2-gray-hover-1050: #23252f;
  --prizm-v2-panel-transparent: rgba(255, 255, 255, 0);
  --prizm-v2-status-primary: #337eff;
  --prizm-v2-status-success: #49ab4d;
  --prizm-v2-status-warning: #ff9800;
  --prizm-v2-status-danger: #f14141;
  --prizm-v2-content-iocn: #8b90a7;
  --prizm-v2-preset-background-body: #ffffff;
  --prizm-v2-background-body-alt: #e6e8ef;
  --prizm-v2-background-dashboard: #e6e8ef;
  --prizm-v2-background-body: #ffffff;
  --prizm-v2-border-widget: #ced1df;
  --prizm-v2-border-forms: #ced1df;
  --prizm-v2-preset-background-primary: --prizm-v2-palette-gray-base-0;
  --prizm-v2-preset-background-secondary: --prizm-v2-palette-gray-base-100;
  --prizm-v2-preset-background-tertiary: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-background-primary-revers: --prizm-v2-palette-gray-base-1000;
  --prizm-v2-preset-border-focus: --prizm-v2-palette-blue-200;
  --prizm-v2-preset-border-primary: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-border-secondary: --prizm-v2-palette-gray-base-300;
  --prizm-v2-preset-table-zebra: --prizm-v2-palette-gray-base-25;
  --prizm-v2-preset-table-header: --prizm-v2-palette-gray-base-100;
  --prizm-v2-preset-table-border-cell: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-table-cell-success: --prizm-v2-palette-green-100;
  --prizm-v2-preset-table-cell-warning: --prizm-v2-palette-orange-100;
  --prizm-v2-preset-table-cell-danger: --prizm-v2-palette-red-100;
  --prizm-v2-preset-table-border-cell-success: --prizm-v2-palette-green-200;
  --prizm-v2-preset-table-border-cell-warning: --prizm-v2-palette-orange-200;
  --prizm-v2-preset-table-border-cell-danger: --prizm-v2-palette-red-200;
  --prizm-v2-preset-panel-transparent: rgba(255, 255, 255, 0);
  --prizm-v2-preset-panel-solid: --prizm-v2-palette-gray-base-0;
  --prizm-v2-preset-form-surface: --prizm-v2-palette-gray-base-25;
  --prizm-v2-preset-form-border: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-control-surface: --prizm-v2-palette-gray-base-25;
  --prizm-v2-preset-control-border: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-content-primary: --prizm-v2-palette-gray-base-1000;
  --prizm-v2-preset-content-secondary: --prizm-v2-palette-gray-base-800;
  --prizm-v2-preset-content-tetreary: --prizm-v2-palette-gray-base-600;
  --prizm-v2-preset-content-revers: --prizm-v2-palette-gray-base-0;
  --prizm-v2-preset-content-disable: --prizm-v2-palette-gray-base-300;
  --prizm-v2-preset-content-link: --prizm-v2-palette-blue-500;
  --prizm-v2-preset-status-primary: --prizm-v2-palette-blue-500;
  --prizm-v2-preset-status-primary-lite: --prizm-v2-palette-blue-100;
  --prizm-v2-preset-status-secondary: --prizm-v2-palette-gray-base-500;
  --prizm-v2-preset-status-secondary-lite: --prizm-v2-palette-gray-base-100;
  --prizm-v2-preset-status-success: --prizm-v2-palette-green-500;
  --prizm-v2-preset-status-success-lite: --prizm-v2-palette-green-100;
  --prizm-v2-preset-status-warning: --prizm-v2-palette-orange-500;
  --prizm-v2-preset-status-warning-lite: --prizm-v2-palette-orange-100;
  --prizm-v2-preset-status-danger: --prizm-v2-palette-red-500;
  --prizm-v2-preset-status-danger-lite: --prizm-v2-palette-red-100;
  --prizm-v2-preset-status-disable: --prizm-v2-palette-gray-base-25;
  --prizm-v2-preset-additional-status-purple: --prizm-v2-palette-purple-500;
  --prizm-v2-preset-additional-status-purple-lite: --prizm-v2-palette-purple-100;
  --prizm-v2-preset-additional-status-teal: --prizm-v2-palette-teal-500;
  --prizm-v2-preset-additional-status-teal-lite: --prizm-v2-palette-teal-100;
  --prizm-v2-preset-additional-status-yellow: --prizm-v2-palette-yellow-500;
  --prizm-v2-preset-additional-status-yellow-lite: --prizm-v2-palette-yellow-100;
  --prizm-v2-preset-additional-status-pink: --prizm-v2-palette-pink-500;
  --prizm-v2-preset-additional-status-pink-lite: --prizm-v2-palette-pink-100;
  --prizm-v2-state-table-header-hover: --prizm-v2-palette-gray-hover-150;
  --prizm-v2-state-table-border-cell-selected: --prizm-v2-palette-gray-base-400;
  --prizm-v2-state-table-row-hover: --prizm-v2-palette-gray-hover-50;
  --prizm-v2-state-table-row-selected: --prizm-v2-palette-blue-200;
  --prizm-v2-state-table-border-row-selected: --prizm-v2-palette-blue-300;
  --prizm-v2-state-table-row-success-hover: --prizm-v2-palette-green-200;
  --prizm-v2-state-table-border-row-success-hover: --prizm-v2-palette-green-300;
  --prizm-v2-state-table-row-warning-hover: --prizm-v2-palette-orange-200;
  --prizm-v2-state-table-border-row-warning-hover: --prizm-v2-palette-orange-300;
  --prizm-v2-state-table-row-danger-hover: --prizm-v2-palette-red-200;
  --prizm-v2-state-table-border-row-danger-hover: --prizm-v2-palette-red-300;
  --prizm-v2-state-button-primary-hover: --prizm-v2-palette-blue-600;
  --prizm-v2-state-button-primary-active: --prizm-v2-palette-blue-700;
  --prizm-v2-state-button-secondary-hover: --prizm-v2-palette-gray-base-600;
  --prizm-v2-state-button-secondary-active: --prizm-v2-palette-gray-base-700;
  --prizm-v2-state-button-success-hover: --prizm-v2-palette-green-600;
  --prizm-v2-state-button-success-active: --prizm-v2-palette-green-700;
  --prizm-v2-state-button-warning-hover: --prizm-v2-palette-orange-600;
  --prizm-v2-state-button-warning-active: --prizm-v2-palette-orange-700;
  --prizm-v2-state-button-danger-hover: --prizm-v2-palette-red-600;
  --prizm-v2-state-button-danger-active: --prizm-v2-palette-red-700;
  --prizm-v2-state-button-ghost-hover: --prizm-v2-palette-gray-hover-50;
  --prizm-v2-state-form-border-hover: --prizm-v2-palette-gray-base-450;
  --prizm-v2-state-content-link-hover: --prizm-v2-palette-blue-600;
  --prizm-v2-state-content-link-active: --prizm-v2-palette-blue-700;
  --prizm-v2-state-content-link-visited: --prizm-v2-palette-blue-400;
  --prizm-v2-state-control-active: --prizm-v2-palette-blue-500;
  --prizm-v2-state-control-active-hover: --prizm-v2-palette-blue-600;
  --prizm-v2-state-control-active-disable: --prizm-v2-palette-blue-300;
  --prizm-v2-state-control-border-hover: --prizm-v2-palette-gray-base-450;
  --prizm-v2-table-zebra: #f9f9fb;
  --prizm-v2-table-text-selected: var(--prizm-v2-gray-base-1000);
  --prizm-v2-table-background-selected: var(--prizm-v2-palette-blue-200);
  --prizm-v2-table-text-default: var(--prizm-v2-gray-base-1000);
  --prizm-v2-table-background-default: white;
  --prizm-v2-table-background-default-hover: var(--prizm-v2-gray-hover-150);
  --prizm-v2-table-text-danger: var(--prizm-v2-palette-red-600);
  --prizm-v2-table-background-danger: var(--prizm-v2-palette-red-100);
  --prizm-v2-table-background-danger-hover: var(--prizm-v2-palette-red-200);
  --prizm-v2-table-text-warning: var(--prizm-v2-palette-orange-600);
  --prizm-v2-table-background-warning: var(--prizm-v2-palette-orange-100);
  --prizm-v2-table-background-warning-hover: var(--prizm-v2-palette-orange-200);
  --prizm-v2-table-text-success: var(--prizm-v2-palette-green-600);
  --prizm-v2-table-background-success: var(--prizm-v2-palette-green-100);
  --prizm-v2-table-background-success-hover: var(--prizm-v2-palette-green-200);
}
*[data-prizm-theme='dark'] {
  --prizm-v2-preset-background-body: #ffffff;
  --prizm-v2-background-body-alt: #45495a;
  --prizm-v2-background-dashboard: #1a1c24;
  --prizm-v2-background-body: #2f323f;
  --prizm-v2-border-widget: #5c6075;
  --prizm-v2-border-forms: #73788e;
  --prizm-v2-preset-content-link: #8dbbff;
  --prizm-v2-table-background-selected: var(--prizm-v2-status-primary);
  --prizm-v2-preset-background-primary: --prizm-v2-palette-gray-base-900;
  --prizm-v2-preset-background-secondary: --prizm-v2-palette-gray-base-1000;
  --prizm-v2-preset-background-tertiary: --prizm-v2-palette-gray-base-1000;
  --prizm-v2-preset-background-primary-revers: --prizm-v2-palette-gray-base-100;
  --prizm-v2-preset-border-focus: --prizm-v2-palette-blue-200;
  --prizm-v2-preset-border-primary: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-border-secondary: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-table-zebra: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-table-header: --prizm-v2-palette-gray-base-800;
  --prizm-v2-preset-table-border-cell: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-table-cell-success: --prizm-v2-palette-green-900;
  --prizm-v2-preset-table-cell-warning: --prizm-v2-palette-orange-900;
  --prizm-v2-preset-table-cell-danger: --prizm-v2-palette-red-900;
  --prizm-v2-preset-table-border-cell-success: --prizm-v2-palette-green-800;
  --prizm-v2-preset-table-border-cell-warning: --prizm-v2-palette-orange-800;
  --prizm-v2-preset-table-border-cell-danger: --prizm-v2-palette-red-800;
  --prizm-v2-preset-panel-transparent: rgba(0, 0, 0, 0);
  --prizm-v2-preset-panel-solid: --prizm-v2-palette-gray-base-900;
  --prizm-v2-preset-form-surface: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-form-border: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-control-surface: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-control-border: --prizm-v2-palette-gray-base-700;
  --prizm-v2-preset-content-primary: --prizm-v2-palette-gray-base-0;
  --prizm-v2-preset-content-secondary: --prizm-v2-palette-gray-base-200;
  --prizm-v2-preset-content-tetreary: --prizm-v2-palette-gray-base-400;
  --prizm-v2-preset-content-revers: --prizm-v2-palette-gray-base-900;
  --prizm-v2-preset-content-disable: --prizm-v2-palette-gray-base-600;
  --prizm-v2-preset-status-primary: --prizm-v2-palette-blue-400;
  --prizm-v2-preset-status-primary-lite: --prizm-v2-palette-blue-800;
  --prizm-v2-preset-status-secondary: --prizm-v2-palette-gray-base-400;
  --prizm-v2-preset-status-secondary-lite: --prizm-v2-palette-gray-base-800;
  --prizm-v2-preset-status-success: --prizm-v2-palette-green-400;
  --prizm-v2-preset-status-success-lite: --prizm-v2-palette-green-800;
  --prizm-v2-preset-status-warning: --prizm-v2-palette-orange-400;
  --prizm-v2-preset-status-warning-lite: --prizm-v2-palette-orange-800;
  --prizm-v2-preset-status-danger: --prizm-v2-palette-red-400;
  --prizm-v2-preset-status-danger-lite: --prizm-v2-palette-red-800;
  --prizm-v2-preset-status-disable: --prizm-v2-palette-gray-base-875;
  --prizm-v2-preset-additional-status-purple: --prizm-v2-palette-purple-400;
  --prizm-v2-preset-additional-status-purple-lite: --prizm-v2-palette-purple-800;
  --prizm-v2-preset-additional-status-teal: --prizm-v2-palette-teal-400;
  --prizm-v2-preset-additional-status-teal-lite: --prizm-v2-palette-teal-800;
  --prizm-v2-preset-additional-status-yellow: --prizm-v2-palette-yellow-400;
  --prizm-v2-preset-additional-status-yellow-lite: --prizm-v2-palette-yellow-800;
  --prizm-v2-preset-additional-status-pink: --prizm-v2-palette-pink-400;
  --prizm-v2-preset-additional-status-pink-lite: --prizm-v2-palette-pink-800;
  --prizm-v2-state-table-header-hover: --prizm-v2-palette-gray-hover-850;
  --prizm-v2-state-table-border-cell-selected: --prizm-v2-palette-gray-base-600;
  --prizm-v2-state-table-row-hover: --prizm-v2-palette-gray-hover-950;
  --prizm-v2-state-table-row-selected: --prizm-v2-palette-blue-800;
  --prizm-v2-state-table-border-row-selected: --prizm-v2-palette-blue-700;
  --prizm-v2-state-table-row-success-hover: --prizm-v2-palette-green-800;
  --prizm-v2-state-table-border-row-success-hover: --prizm-v2-palette-green-700;
  --prizm-v2-state-table-row-warning-hover: --prizm-v2-palette-orange-800;
  --prizm-v2-state-table-border-row-warning-hover: --prizm-v2-palette-orange-700;
  --prizm-v2-state-table-row-danger-hover: --prizm-v2-palette-red-800;
  --prizm-v2-state-table-border-row-danger-hover: --prizm-v2-palette-red-700;
  --prizm-v2-state-button-primary-hover: --prizm-v2-palette-blue-300;
  --prizm-v2-state-button-primary-active: --prizm-v2-palette-blue-400;
  --prizm-v2-state-button-secondary-hover: --prizm-v2-palette-gray-base-300;
  --prizm-v2-state-button-secondary-active: --prizm-v2-palette-gray-base-400;
  --prizm-v2-state-button-success-hover: --prizm-v2-palette-green-300;
  --prizm-v2-state-button-success-active: --prizm-v2-palette-green-400;
  --prizm-v2-state-button-warning-hover: --prizm-v2-palette-orange-300;
  --prizm-v2-state-button-warning-active: --prizm-v2-palette-orange-400;
  --prizm-v2-state-button-danger-hover: --prizm-v2-palette-red-300;
  --prizm-v2-state-button-danger-active: --prizm-v2-palette-red-400;
  --prizm-v2-state-button-ghost-hover: --prizm-v2-palette-gray-hover-950;
  --prizm-v2-state-form-border-hover: --prizm-v2-palette-gray-base-550;
  --prizm-v2-state-content-link-hover: --prizm-v2-palette-blue-300;
  --prizm-v2-state-content-link-active: --prizm-v2-palette-blue-400;
  --prizm-v2-state-content-link-visited: --prizm-v2-palette-blue-600;
  --prizm-v2-state-control-active: --prizm-v2-palette-blue-400;
  --prizm-v2-state-control-active-hover: --prizm-v2-palette-blue-300;
  --prizm-v2-state-control-active-disable: --prizm-v2-palette-blue-600;
  --prizm-v2-state-control-border-hover: --prizm-v2-palette-gray-base-550;
  --prizm-v2-table-zebra: #f9f9fb;
  --prizm-v2-table-text-selected: var(--prizm-v2-gray-base-1000);
  --prizm-v2-table-background-selected: var(--prizm-v2-palette-blue-200);
  --prizm-v2-table-text-default: var(--prizm-v2-gray-base-1000);
  --prizm-v2-table-background-default: white;
  --prizm-v2-table-background-default-hover: var(--prizm-v2-gray-hover-150);
  --prizm-v2-table-text-danger: var(--prizm-v2-palette-red-600);
  --prizm-v2-table-background-danger: var(--prizm-v2-palette-red-100);
  --prizm-v2-table-background-danger-hover: var(--prizm-v2-palette-red-200);
  --prizm-v2-table-text-warning: var(--prizm-v2-palette-orange-600);
  --prizm-v2-table-background-warning: var(--prizm-v2-palette-orange-100);
  --prizm-v2-table-background-warning-hover: var(--prizm-v2-palette-orange-200);
  --prizm-v2-table-text-success: var(--prizm-v2-palette-green-600);
  --prizm-v2-table-background-success: var(--prizm-v2-palette-green-100);
  --prizm-v2-table-background-success-hover: var(--prizm-v2-palette-green-200);
  --prizm-v2-table-zebra: #2f323f;
  --prizm-v2-table-text-default: var(--prizm-v2-gray-base-0);
  --prizm-v2-table-background-default: #20222b;
  --prizm-v2-table-background-default-hover: var(--prizm-v2-gray-hover-950);
  --prizm-v2-table-background-selected: var(--prizm-v2-palette-blue-800);
  --prizm-v2-table-text-selected: var(--prizm-v2-gray-base-0);
  --prizm-v2-table-text-danger: var(--prizm-v2-palette-red-400);
  --prizm-v2-table-background-danger: var(--prizm-v2-palette-red-900);
  --prizm-v2-table-background-danger-hover: var(--prizm-v2-palette-red-800);
  --prizm-v2-table-text-warning: var(--prizm-v2-palette-orange-400);
  --prizm-v2-table-background-warning: var(--prizm-v2-palette-orange-900);
  --prizm-v2-table-background-warning-hover: var(--prizm-v2-palette-orange-800);
  --prizm-v2-table-text-success: var(--prizm-v2-palette-green-400);
  --prizm-v2-table-background-success: var(--prizm-v2-palette-green-900);
  --prizm-v2-table-background-success-hover: var(--prizm-v2-palette-green-800);
}
/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
/*
Link - https://www.figma.com/file/lR03OmslN1ngsyglw65gKK/%F0%9F%8E%A8-Light-theme-V2?node-id=1407%3A16584&t=ITRL3bjTR3nj2syW-0
*/
:root {
  --prizm-v3-palette-shadow-dark: rgba(0, 0, 0, 0.5);
  --prizm-v3-palette-shadow-light: rgba(191, 198, 215, 0.5);
  --prizm-v3-palette-0-light: #ffffff;
  --prizm-v3-palette-1200-light: #0f1115;
  --prizm-v3-palette-gray-h50-light: #f8f8f9;
  --prizm-v3-palette-gray-100-light: #f3f4f5;
  --prizm-v3-palette-gray-h150-light: #ebedef;
  --prizm-v3-palette-gray-200-light: #e0e3e7;
  --prizm-v3-palette-gray-h250-light: #d4d8dd;
  --prizm-v3-palette-gray-300-light: #c3c7cf;
  --prizm-v3-palette-gray-h350-light: #b7bcc6;
  --prizm-v3-palette-gray-400-light: #a7acb8;
  --prizm-v3-palette-gray-h450-light: #9ba1af;
  --prizm-v3-palette-gray-500-light: #8a91a2;
  --prizm-v3-palette-gray-h550-light: #7e8799;
  --prizm-v3-palette-gray-600-light: #6e778c;
  --prizm-v3-palette-gray-h650-light: #646d84;
  --prizm-v3-palette-gray-700-light: #545f77;
  --prizm-v3-palette-gray-h750-light: #4c556b;
  --prizm-v3-palette-gray-800-light: #3f4759;
  --prizm-v3-palette-gray-h850-light: #373e4e;
  --prizm-v3-palette-gray-900-light: #2c313d;
  --prizm-v3-palette-gray-h950-light: #292e3a;
  --prizm-v3-palette-gray-1000-light: #252a35;
  --prizm-v3-palette-gray-h1050-light: #222731;
  --prizm-v3-palette-gray-1100-light: #1e222a;
  --prizm-v3-palette-gray-h1150-light: #181b22;
  --prizm-v3-palette-blue-h50-light: #f6f8ff;
  --prizm-v3-palette-blue-100-light: #f0f4ff;
  --prizm-v3-palette-blue-h150-light: #e7edff;
  --prizm-v3-palette-blue-200-light: #d9e2ff;
  --prizm-v3-palette-blue-h250-light: #cad6fe;
  --prizm-v3-palette-blue-300-light: #b4c4fd;
  --prizm-v3-palette-blue-h350-light: #a4b8fd;
  --prizm-v3-palette-blue-400-light: #8da7fd;
  --prizm-v3-palette-blue-h450-light: #7d9bfd;
  --prizm-v3-palette-blue-500-light: #6689fd;
  --prizm-v3-palette-blue-h550-light: #587df7;
  --prizm-v3-palette-blue-600-light: #436cee;
  --prizm-v3-palette-blue-h650-light: #3d63da;
  --prizm-v3-palette-blue-700-light: #3555bd;
  --prizm-v3-palette-blue-h750-light: #304daa;
  --prizm-v3-palette-blue-800-light: #28408d;
  --prizm-v3-palette-blue-h850-light: #23387c;
  --prizm-v3-palette-blue-900-light: #1b2b62;
  --prizm-v3-palette-blue-h950-light: #1a295d;
  --prizm-v3-palette-blue-1000-light: #182556;
  --prizm-v3-palette-blue-h1050-light: #16234f;
  --prizm-v3-palette-blue-1100-light: #132045;
  --prizm-v3-palette-blue-h1150-light: #111a32;
  --prizm-v3-palette-green-h50-light: #f5faf2;
  --prizm-v3-palette-green-100-light: #eff6ea;
  --prizm-v3-palette-green-h150-light: #e6f0dd;
  --prizm-v3-palette-green-200-light: #d8e7ca;
  --prizm-v3-palette-green-h250-light: #c9deb6;
  --prizm-v3-palette-green-300-light: #b2d198;
  --prizm-v3-palette-green-h350-light: #a3c784;
  --prizm-v3-palette-green-400-light: #8cb966;
  --prizm-v3-palette-green-h450-light: #7caf52;
  --prizm-v3-palette-green-500-light: #65a134;
  --prizm-v3-palette-green-h550-light: #5a9727;
  --prizm-v3-palette-green-600-light: #498714;
  --prizm-v3-palette-green-h650-light: #3e7c13;
  --prizm-v3-palette-green-700-light: #3b6a15;
  --prizm-v3-palette-green-h750-light: #366015;
  --prizm-v3-palette-green-800-light: #2f5014;
  --prizm-v3-palette-green-h850-light: #2a4513;
  --prizm-v3-palette-green-900-light: #223512;
  --prizm-v3-palette-green-h950-light: #203212;
  --prizm-v3-palette-green-1000-light: #1e2e11;
  --prizm-v3-palette-green-h1050-light: #1c2b0f;
  --prizm-v3-palette-green-1100-light: #1a260d;
  --prizm-v3-palette-green-h1150-light: #161e10;
  --prizm-v3-palette-yellow-h50-light: #fef8e3;
  --prizm-v3-palette-yellow-100-light: #fdf3d1;
  --prizm-v3-palette-yellow-h150-light: #fbecb3;
  --prizm-v3-palette-yellow-200-light: #f9e187;
  --prizm-v3-palette-yellow-h250-light: #f4d560;
  --prizm-v3-palette-yellow-300-light: #edc225;
  --prizm-v3-palette-yellow-h350-light: #e1b725;
  --prizm-v3-palette-yellow-400-light: #cea724;
  --prizm-v3-palette-yellow-h450-light: #c29d24;
  --prizm-v3-palette-yellow-500-light: #af8d23;
  --prizm-v3-palette-yellow-h550-light: #a28322;
  --prizm-v3-palette-yellow-600-light: #8f7420;
  --prizm-v3-palette-yellow-h650-light: #836a1e;
  --prizm-v3-palette-yellow-700-light: #725b1c;
  --prizm-v3-palette-yellow-h750-light: #67521a;
  --prizm-v3-palette-yellow-800-light: #564518;
  --prizm-v3-palette-yellow-h850-light: #4b3d17;
  --prizm-v3-palette-yellow-900-light: #3b3015;
  --prizm-v3-palette-yellow-h950-light: #382d14;
  --prizm-v3-palette-yellow-1000-light: #342912;
  --prizm-v3-palette-yellow-h1050-light: #302511;
  --prizm-v3-palette-yellow-1100-light: #29200f;
  --prizm-v3-palette-yellow-h1150-light: #1f1a11;
  --prizm-v3-palette-orange-h50-light: #fef7ee;
  --prizm-v3-palette-orange-100-light: #fdf1e2;
  --prizm-v3-palette-orange-h150-light: #fbe9d1;
  --prizm-v3-palette-orange-200-light: #f8deb8;
  --prizm-v3-palette-orange-h250-light: #f6d09a;
  --prizm-v3-palette-orange-300-light: #f2bc6d;
  --prizm-v3-palette-orange-h350-light: #f0ae51;
  --prizm-v3-palette-orange-400-light: #ec9827;
  --prizm-v3-palette-orange-h450-light: #de8e27;
  --prizm-v3-palette-orange-500-light: #c98027;
  --prizm-v3-palette-orange-h550-light: #bb7725;
  --prizm-v3-palette-orange-600-light: #a66923;
  --prizm-v3-palette-orange-h650-light: #986122;
  --prizm-v3-palette-orange-700-light: #835420;
  --prizm-v3-palette-orange-h750-light: #764b1e;
  --prizm-v3-palette-orange-800-light: #623e1b;
  --prizm-v3-palette-orange-h850-light: #553619;
  --prizm-v3-palette-orange-900-light: #422b16;
  --prizm-v3-palette-orange-h950-light: #3e2915;
  --prizm-v3-palette-orange-1000-light: #392513;
  --prizm-v3-palette-orange-h1050-light: #352312;
  --prizm-v3-palette-orange-1100-light: #2e1f11;
  --prizm-v3-palette-orange-h1150-light: #221913;
  --prizm-v3-palette-red-h50-light: #fdf7f5;
  --prizm-v3-palette-red-100-light: #fcf1ee;
  --prizm-v3-palette-red-h150-light: #fae9e4;
  --prizm-v3-palette-red-200-light: #f7dcd4;
  --prizm-v3-palette-red-h250-light: #f4cec3;
  --prizm-v3-palette-red-300-light: #f0baa9;
  --prizm-v3-palette-red-h350-light: #eeab99;
  --prizm-v3-palette-red-400-light: #eb9481;
  --prizm-v3-palette-red-h450-light: #e9836f;
  --prizm-v3-palette-red-500-light: #e66a54;
  --prizm-v3-palette-red-h550-light: #df5a46;
  --prizm-v3-palette-red-600-light: #d44332;
  --prizm-v3-palette-red-h650-light: #c13f2f;
  --prizm-v3-palette-red-700-light: #a5382a;
  --prizm-v3-palette-red-h750-light: #943427;
  --prizm-v3-palette-red-800-light: #7a2d22;
  --prizm-v3-palette-red-h850-light: #6a291e;
  --prizm-v3-palette-red-900-light: #512218;
  --prizm-v3-palette-red-h950-light: #4d2017;
  --prizm-v3-palette-red-1000-light: #461e15;
  --prizm-v3-palette-red-h1050-light: #401c14;
  --prizm-v3-palette-red-1050-light: #371912;
  --prizm-v3-palette-red-h1150-light: #271613;
  --prizm-v3-palette-0-dark: #0f1115;
  --prizm-v3-palette-1200-dark: #ffffff;
  --prizm-v3-palette-gray-h50-dark: #181b22;
  --prizm-v3-palette-gray-100-dark: #1e222a;
  --prizm-v3-palette-gray-h150-dark: #222731;
  --prizm-v3-palette-gray-200-dark: #252a35;
  --prizm-v3-palette-gray-h250-dark: #292e3a;
  --prizm-v3-palette-gray-300-dark: #2c313d;
  --prizm-v3-palette-gray-h350-dark: #373e4e;
  --prizm-v3-palette-gray-400-dark: #3f4759;
  --prizm-v3-palette-gray-h450-dark: #4c556b;
  --prizm-v3-palette-gray-500-dark: #545f77;
  --prizm-v3-palette-gray-h550-dark: #646d84;
  --prizm-v3-palette-gray-600-dark: #6e778c;
  --prizm-v3-palette-gray-h650-dark: #7e8799;
  --prizm-v3-palette-gray-700-dark: #8a91a2;
  --prizm-v3-palette-gray-h750-dark: #9ba1af;
  --prizm-v3-palette-gray-800-dark: #a7acb8;
  --prizm-v3-palette-gray-h850-dark: #b7bcc6;
  --prizm-v3-palette-gray-900-dark: #c3c7cf;
  --prizm-v3-palette-gray-h950-dark: #d4d8dd;
  --prizm-v3-palette-gray-1000-dark: #e0e3e7;
  --prizm-v3-palette-gray-h1050-dark: #ebedef;
  --prizm-v3-palette-gray-1100-dark: #f3f4f5;
  --prizm-v3-palette-gray-h1150-dark: #f8f8f9;
  --prizm-v3-palette-blue-h50-dark: #111a32;
  --prizm-v3-palette-blue-100-dark: #132045;
  --prizm-v3-palette-blue-h150-dark: #16234f;
  --prizm-v3-palette-blue-200-dark: #182556;
  --prizm-v3-palette-blue-h250-dark: #1a295d;
  --prizm-v3-palette-blue-300-dark: #1b2b62;
  --prizm-v3-palette-blue-h350-dark: #23387c;
  --prizm-v3-palette-blue-400-dark: #28408d;
  --prizm-v3-palette-blue-h450-dark: #304daa;
  --prizm-v3-palette-blue-500-dark: #3555bd;
  --prizm-v3-palette-blue-h550-dark: #3d63da;
  --prizm-v3-palette-blue-600-dark: #436cee;
  --prizm-v3-palette-blue-h650-dark: #587df7;
  --prizm-v3-palette-blue-700-dark: #6689fd;
  --prizm-v3-palette-blue-h750-dark: #7d9bfd;
  --prizm-v3-palette-blue-800-dark: #8da7fd;
  --prizm-v3-palette-blue-h850-dark: #a4b8fd;
  --prizm-v3-palette-blue-900-dark: #b4c4fd;
  --prizm-v3-palette-blue-h950-dark: #cad6fe;
  --prizm-v3-palette-blue-1000-dark: #d9e2ff;
  --prizm-v3-palette-blue-h1050-dark: #e7edff;
  --prizm-v3-palette-blue-1100-dark: #f0f4ff;
  --prizm-v3-palette-blue-h1150-dark: #f4f7fe;
  --prizm-v3-palette-green-h50-dark: #161e10;
  --prizm-v3-palette-green-100-dark: #1a260d;
  --prizm-v3-palette-green-h150-dark: #1c2b0f;
  --prizm-v3-palette-green-200-dark: #1e2e11;
  --prizm-v3-palette-green-h250-dark: #203212;
  --prizm-v3-palette-green-300-dark: #223512;
  --prizm-v3-palette-green-h350-dark: #2a4513;
  --prizm-v3-palette-green-400-dark: #2f5014;
  --prizm-v3-palette-green-h450-dark: #366015;
  --prizm-v3-palette-green-500-dark: #3b6a15;
  --prizm-v3-palette-green-h550-dark: #3e7c13;
  --prizm-v3-palette-green-600-dark: #498714;
  --prizm-v3-palette-green-h650-dark: #5a9727;
  --prizm-v3-palette-green-700-dark: #65a134;
  --prizm-v3-palette-green-h750-dark: #7caf52;
  --prizm-v3-palette-green-800-dark: #8cb966;
  --prizm-v3-palette-green-h850-dark: #a3c784;
  --prizm-v3-palette-green-900-dark: #b2d198;
  --prizm-v3-palette-green-h950-dark: #c9deb6;
  --prizm-v3-palette-green-1000-dark: #d8e7ca;
  --prizm-v3-palette-green-h1050-dark: #e6f0dd;
  --prizm-v3-palette-green-1100-dark: #eff6ea;
  --prizm-v3-palette-green-h1150-dark: #f5faf2;
  --prizm-v3-palette-yellow-h50-dark: #1f1a11;
  --prizm-v3-palette-yellow-100-dark: #29200f;
  --prizm-v3-palette-yellow-h150-dark: #302511;
  --prizm-v3-palette-yellow-200-dark: #342912;
  --prizm-v3-palette-yellow-h250-dark: #382d14;
  --prizm-v3-palette-yellow-300-dark: #3b3015;
  --prizm-v3-palette-yellow-h350-dark: #4b3d17;
  --prizm-v3-palette-yellow-400-dark: #564518;
  --prizm-v3-palette-yellow-h450-dark: #67521a;
  --prizm-v3-palette-yellow-500-dark: #725b1c;
  --prizm-v3-palette-yellow-h550-dark: #836a1e;
  --prizm-v3-palette-yellow-600-dark: #8f7420;
  --prizm-v3-palette-yellow-h650-dark: #a28322;
  --prizm-v3-palette-yellow-700-dark: #af8d23;
  --prizm-v3-palette-yellow-h750-dark: #c29d24;
  --prizm-v3-palette-yellow-800-dark: #cea724;
  --prizm-v3-palette-yellow-h850-dark: #e1b725;
  --prizm-v3-palette-yellow-900-dark: #edc225;
  --prizm-v3-palette-yellow-h950-dark: #f4d560;
  --prizm-v3-palette-yellow-1000-dark: #f9e187;
  --prizm-v3-palette-yellow-h1050-dark: #fbecb3;
  --prizm-v3-palette-yellow-1100-dark: #fdf3d1;
  --prizm-v3-palette-yellow-h1150-dark: #fef8e3;
  --prizm-v3-palette-orange-h50-dark: #221913;
  --prizm-v3-palette-orange-100-dark: #2e1f11;
  --prizm-v3-palette-orange-h150-dark: #352312;
  --prizm-v3-palette-orange-200-dark: #392513;
  --prizm-v3-palette-orange-h250-dark: #3e2915;
  --prizm-v3-palette-orange-300-dark: #422b16;
  --prizm-v3-palette-orange-h350-dark: #553619;
  --prizm-v3-palette-orange-400-dark: #623e1b;
  --prizm-v3-palette-orange-h450-dark: #764b1e;
  --prizm-v3-palette-orange-500-dark: #835420;
  --prizm-v3-palette-orange-h550-dark: #986122;
  --prizm-v3-palette-orange-600-dark: #a66923;
  --prizm-v3-palette-orange-h650-dark: #bb7725;
  --prizm-v3-palette-orange-700-dark: #c98027;
  --prizm-v3-palette-orange-h750-dark: #de8e27;
  --prizm-v3-palette-orange-800-dark: #ec9827;
  --prizm-v3-palette-orange-h850-dark: #f0ae51;
  --prizm-v3-palette-orange-900-dark: #f2bc6d;
  --prizm-v3-palette-orange-h950-dark: #f6d09a;
  --prizm-v3-palette-orange-1000-dark: #f8deb8;
  --prizm-v3-palette-orange-h1050-dark: #fbe9d1;
  --prizm-v3-palette-orange-1100-dark: #fdf1e2;
  --prizm-v3-palette-orange-h1150-dark: #fef7ee;
  --prizm-v3-palette-red-h50-dark: #271613;
  --prizm-v3-palette-red-100-dark: #371912;
  --prizm-v3-palette-red-h150-dark: #401c14;
  --prizm-v3-palette-red-200-dark: #461e15;
  --prizm-v3-palette-red-h250-dark: #4d2017;
  --prizm-v3-palette-red-300-dark: #512218;
  --prizm-v3-palette-red-h350-dark: #6a291e;
  --prizm-v3-palette-red-400-dark: #7a2d22;
  --prizm-v3-palette-red-h450-dark: #943427;
  --prizm-v3-palette-red-500-dark: #a5382a;
  --prizm-v3-palette-red-h550-dark: #c13f2f;
  --prizm-v3-palette-red-600-dark: #d44332;
  --prizm-v3-palette-red-h650-dark: #df5a46;
  --prizm-v3-palette-red-700-dark: #e66a54;
  --prizm-v3-palette-red-h750-dark: #e9836f;
  --prizm-v3-palette-red-800-dark: #eb9481;
  --prizm-v3-palette-red-h850-dark: #eeab99;
  --prizm-v3-palette-red-900-dark: #f0baa9;
  --prizm-v3-palette-red-h950-dark: #f4cec3;
  --prizm-v3-palette-red-1000-dark: #f7dcd4;
  --prizm-v3-palette-red-h1050-dark: #fae9e4;
  --prizm-v3-palette-red-1050-dark: #fcf1ee;
  --prizm-v3-palette-red-h1150-dark: #fdf7f5;
}
:root,
*[data-prizm-theme='light'] {
  --prizm-v3-palette-0: var(--prizm-v3-palette-0-light);
  --prizm-v3-palette-1200: var(--prizm-v3-palette-1200-light);
  --prizm-v3-palette-gray-h50: var(--prizm-v3-palette-gray-h50-light);
  --prizm-v3-palette-gray-100: var(--prizm-v3-palette-gray-100-light);
  --prizm-v3-palette-gray-h150: var(--prizm-v3-palette-gray-h150-light);
  --prizm-v3-palette-gray-200: var(--prizm-v3-palette-gray-200-light);
  --prizm-v3-palette-gray-h250: var(--prizm-v3-palette-gray-h250-light);
  --prizm-v3-palette-gray-300: var(--prizm-v3-palette-gray-300-light);
  --prizm-v3-palette-gray-h350: var(--prizm-v3-palette-gray-h350-light);
  --prizm-v3-palette-gray-400: var(--prizm-v3-palette-gray-400-light);
  --prizm-v3-palette-gray-h450: var(--prizm-v3-palette-gray-h450-light);
  --prizm-v3-palette-gray-500: var(--prizm-v3-palette-gray-500-light);
  --prizm-v3-palette-gray-h550: var(--prizm-v3-palette-gray-h550-light);
  --prizm-v3-palette-gray-600: var(--prizm-v3-palette-gray-600-light);
  --prizm-v3-palette-gray-h650: var(--prizm-v3-palette-gray-h650-light);
  --prizm-v3-palette-gray-700: var(--prizm-v3-palette-gray-700-light);
  --prizm-v3-palette-gray-h750: var(--prizm-v3-palette-gray-h750-light);
  --prizm-v3-palette-gray-800: var(--prizm-v3-palette-gray-800-light);
  --prizm-v3-palette-gray-h850: var(--prizm-v3-palette-gray-h850-light);
  --prizm-v3-palette-gray-900: var(--prizm-v3-palette-gray-900-light);
  --prizm-v3-palette-gray-h950: var(--prizm-v3-palette-gray-h950-light);
  --prizm-v3-palette-gray-1000: var(--prizm-v3-palette-gray-1000-light);
  --prizm-v3-palette-gray-h1050: var(--prizm-v3-palette-gray-h1050-light);
  --prizm-v3-palette-gray-1100: var(--prizm-v3-palette-gray-1100-light);
  --prizm-v3-palette-gray-h1150: var(--prizm-v3-palette-gray-h1150-light);
  --prizm-v3-palette-blue-h50: var(--prizm-v3-palette-blue-h50-light);
  --prizm-v3-palette-blue-100: var(--prizm-v3-palette-blue-100-light);
  --prizm-v3-palette-blue-h150: var(--prizm-v3-palette-blue-h150-light);
  --prizm-v3-palette-blue-200: var(--prizm-v3-palette-blue-200-light);
  --prizm-v3-palette-blue-h250: var(--prizm-v3-palette-blue-h250-light);
  --prizm-v3-palette-blue-300: var(--prizm-v3-palette-blue-300-light);
  --prizm-v3-palette-blue-h350: var(--prizm-v3-palette-blue-h350-light);
  --prizm-v3-palette-blue-400: var(--prizm-v3-palette-blue-400-light);
  --prizm-v3-palette-blue-h450: var(--prizm-v3-palette-blue-h450-light);
  --prizm-v3-palette-blue-500: var(--prizm-v3-palette-blue-500-light);
  --prizm-v3-palette-blue-h550: var(--prizm-v3-palette-blue-h550-light);
  --prizm-v3-palette-blue-600: var(--prizm-v3-palette-blue-600-light);
  --prizm-v3-palette-blue-h650: var(--prizm-v3-palette-blue-h650-light);
  --prizm-v3-palette-blue-700: var(--prizm-v3-palette-blue-700-light);
  --prizm-v3-palette-blue-h750: var(--prizm-v3-palette-blue-h750-light);
  --prizm-v3-palette-blue-800: var(--prizm-v3-palette-blue-800-light);
  --prizm-v3-palette-blue-h850: var(--prizm-v3-palette-blue-h850-light);
  --prizm-v3-palette-blue-900: var(--prizm-v3-palette-blue-900-light);
  --prizm-v3-palette-blue-h950: var(--prizm-v3-palette-blue-h950-light);
  --prizm-v3-palette-blue-1000: var(--prizm-v3-palette-blue-1000-light);
  --prizm-v3-palette-blue-h1050: var(--prizm-v3-palette-blue-h1050-light);
  --prizm-v3-palette-blue-1100: var(--prizm-v3-palette-blue-1100-light);
  --prizm-v3-palette-blue-h1150: var(--prizm-v3-palette-blue-h1150-light);
  --prizm-v3-palette-green-h50: var(--prizm-v3-palette-green-h50-light);
  --prizm-v3-palette-green-100: var(--prizm-v3-palette-green-100-light);
  --prizm-v3-palette-green-h150: var(--prizm-v3-palette-green-h150-light);
  --prizm-v3-palette-green-200: var(--prizm-v3-palette-green-200-light);
  --prizm-v3-palette-green-h250: var(--prizm-v3-palette-green-h250-light);
  --prizm-v3-palette-green-300: var(--prizm-v3-palette-green-300-light);
  --prizm-v3-palette-green-h350: var(--prizm-v3-palette-green-h350-light);
  --prizm-v3-palette-green-400: var(--prizm-v3-palette-green-400-light);
  --prizm-v3-palette-green-h450: var(--prizm-v3-palette-green-h450-light);
  --prizm-v3-palette-green-500: var(--prizm-v3-palette-green-500-light);
  --prizm-v3-palette-green-h550: var(--prizm-v3-palette-green-h550-light);
  --prizm-v3-palette-green-600: var(--prizm-v3-palette-green-600-light);
  --prizm-v3-palette-green-h650: var(--prizm-v3-palette-green-h650-light);
  --prizm-v3-palette-green-700: var(--prizm-v3-palette-green-700-light);
  --prizm-v3-palette-green-h750: var(--prizm-v3-palette-green-h750-light);
  --prizm-v3-palette-green-800: var(--prizm-v3-palette-green-800-light);
  --prizm-v3-palette-green-h850: var(--prizm-v3-palette-green-h850-light);
  --prizm-v3-palette-green-900: var(--prizm-v3-palette-green-900-light);
  --prizm-v3-palette-green-h950: var(--prizm-v3-palette-green-h950-light);
  --prizm-v3-palette-green-1000: var(--prizm-v3-palette-green-1000-light);
  --prizm-v3-palette-green-h1050: var(--prizm-v3-palette-green-h1050-light);
  --prizm-v3-palette-green-1100: var(--prizm-v3-palette-green-1100-light);
  --prizm-v3-palette-green-h1150: var(--prizm-v3-palette-green-h1150-light);
  --prizm-v3-palette-yellow-h50: var(--prizm-v3-palette-yellow-h50-light);
  --prizm-v3-palette-yellow-100: var(--prizm-v3-palette-yellow-100-light);
  --prizm-v3-palette-yellow-h150: var(--prizm-v3-palette-yellow-h150-light);
  --prizm-v3-palette-yellow-200: var(--prizm-v3-palette-yellow-200-light);
  --prizm-v3-palette-yellow-h250: var(--prizm-v3-palette-yellow-h250-light);
  --prizm-v3-palette-yellow-300: var(--prizm-v3-palette-yellow-300-light);
  --prizm-v3-palette-yellow-h350: var(--prizm-v3-palette-yellow-h350-light);
  --prizm-v3-palette-yellow-400: var(--prizm-v3-palette-yellow-400-light);
  --prizm-v3-palette-yellow-h450: var(--prizm-v3-palette-yellow-h450-light);
  --prizm-v3-palette-yellow-500: var(--prizm-v3-palette-yellow-500-light);
  --prizm-v3-palette-yellow-h550: var(--prizm-v3-palette-yellow-h550-light);
  --prizm-v3-palette-yellow-600: var(--prizm-v3-palette-yellow-600-light);
  --prizm-v3-palette-yellow-h650: var(--prizm-v3-palette-yellow-h650-light);
  --prizm-v3-palette-yellow-700: var(--prizm-v3-palette-yellow-700-light);
  --prizm-v3-palette-yellow-h750: var(--prizm-v3-palette-yellow-h750-light);
  --prizm-v3-palette-yellow-800: var(--prizm-v3-palette-yellow-800-light);
  --prizm-v3-palette-yellow-h850: var(--prizm-v3-palette-yellow-h850-light);
  --prizm-v3-palette-yellow-900: var(--prizm-v3-palette-yellow-900-light);
  --prizm-v3-palette-yellow-h950: var(--prizm-v3-palette-yellow-h950-light);
  --prizm-v3-palette-yellow-1000: var(--prizm-v3-palette-yellow-1000-light);
  --prizm-v3-palette-yellow-h1050: var(--prizm-v3-palette-yellow-h1050-light);
  --prizm-v3-palette-yellow-1100: var(--prizm-v3-palette-yellow-1100-light);
  --prizm-v3-palette-yellow-h1150: var(--prizm-v3-palette-yellow-h1150-light);
  --prizm-v3-palette-orange-h50: var(--prizm-v3-palette-orange-h50-light);
  --prizm-v3-palette-orange-100: var(--prizm-v3-palette-orange-100-light);
  --prizm-v3-palette-orange-h150: var(--prizm-v3-palette-orange-h150-light);
  --prizm-v3-palette-orange-200: var(--prizm-v3-palette-orange-200-light);
  --prizm-v3-palette-orange-h250: var(--prizm-v3-palette-orange-h250-light);
  --prizm-v3-palette-orange-300: var(--prizm-v3-palette-orange-300-light);
  --prizm-v3-palette-orange-h350: var(--prizm-v3-palette-orange-h350-light);
  --prizm-v3-palette-orange-400: var(--prizm-v3-palette-orange-400-light);
  --prizm-v3-palette-orange-h450: var(--prizm-v3-palette-orange-h450-light);
  --prizm-v3-palette-orange-500: var(--prizm-v3-palette-orange-500-light);
  --prizm-v3-palette-orange-h550: var(--prizm-v3-palette-orange-h550-light);
  --prizm-v3-palette-orange-600: var(--prizm-v3-palette-orange-600-light);
  --prizm-v3-palette-orange-h650: var(--prizm-v3-palette-orange-h650-light);
  --prizm-v3-palette-orange-700: var(--prizm-v3-palette-orange-700-light);
  --prizm-v3-palette-orange-h750: var(--prizm-v3-palette-orange-h750-light);
  --prizm-v3-palette-orange-800: var(--prizm-v3-palette-orange-800-light);
  --prizm-v3-palette-orange-h850: var(--prizm-v3-palette-orange-h850-light);
  --prizm-v3-palette-orange-900: var(--prizm-v3-palette-orange-900-light);
  --prizm-v3-palette-orange-h950: var(--prizm-v3-palette-orange-h950-light);
  --prizm-v3-palette-orange-1000: var(--prizm-v3-palette-orange-1000-light);
  --prizm-v3-palette-orange-h1050: var(--prizm-v3-palette-orange-h1050-light);
  --prizm-v3-palette-orange-1100: var(--prizm-v3-palette-orange-1100-light);
  --prizm-v3-palette-orange-h1150: var(--prizm-v3-palette-orange-h1150-light);
  --prizm-v3-palette-red-h50: var(--prizm-v3-palette-red-h50-light);
  --prizm-v3-palette-red-100: var(--prizm-v3-palette-red-100-light);
  --prizm-v3-palette-red-h150: var(--prizm-v3-palette-red-h150-light);
  --prizm-v3-palette-red-200: var(--prizm-v3-palette-red-200-light);
  --prizm-v3-palette-red-h250: var(--prizm-v3-palette-red-h250-light);
  --prizm-v3-palette-red-300: var(--prizm-v3-palette-red-300-light);
  --prizm-v3-palette-red-h350: var(--prizm-v3-palette-red-h350-light);
  --prizm-v3-palette-red-400: var(--prizm-v3-palette-red-400-light);
  --prizm-v3-palette-red-h450: var(--prizm-v3-palette-red-h450-light);
  --prizm-v3-palette-red-500: var(--prizm-v3-palette-red-500-light);
  --prizm-v3-palette-red-h550: var(--prizm-v3-palette-red-h550-light);
  --prizm-v3-palette-red-600: var(--prizm-v3-palette-red-600-light);
  --prizm-v3-palette-red-h650: var(--prizm-v3-palette-red-h650-light);
  --prizm-v3-palette-red-700: var(--prizm-v3-palette-red-700-light);
  --prizm-v3-palette-red-h750: var(--prizm-v3-palette-red-h750-light);
  --prizm-v3-palette-red-800: var(--prizm-v3-palette-red-800-light);
  --prizm-v3-palette-red-h850: var(--prizm-v3-palette-red-h850-light);
  --prizm-v3-palette-red-900: var(--prizm-v3-palette-red-900-light);
  --prizm-v3-palette-red-h950: var(--prizm-v3-palette-red-h950-light);
  --prizm-v3-palette-red-1000: var(--prizm-v3-palette-red-1000-light);
  --prizm-v3-palette-red-h1050: var(--prizm-v3-palette-red-h1050-light);
  --prizm-v3-palette-red-1050: var(--prizm-v3-palette-red-1050-light);
  --prizm-v3-palette-red-h1150: var(--prizm-v3-palette-red-h1150-light);
  --prizm-v3-text-icon-primary: var(--prizm-v3-palette-1200);
  --prizm-v3-text-icon-secondary: var(--prizm-v3-palette-gray-800);
  --prizm-v3-text-icon-tertiary: var(--prizm-v3-palette-gray-600);
  --prizm-v3-text-icon-disable: var(--prizm-v3-palette-gray-400);
  --prizm-v3-text-icon-link: var(--prizm-v3-palette-blue-600);
  --prizm-v3-text-icon-link-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-text-icon-link-visited: var(--prizm-v3-palette-blue-400);
  --prizm-v3-text-icon-reverse: var(--prizm-v3-palette-0);
  --prizm-v3-text-icon-exception: var(--prizm-v3-palette-0);
  --prizm-v3-background-fill-primary: var(--prizm-v3-palette-0);
  --prizm-v3-background-fill-secondary: var(--prizm-v3-palette-gray-100);
  --prizm-v3-background-fill-panel: var(--prizm-v3-palette-0);
  --prizm-v3-background-fill-overlay: var(--prizm-v3-palette-0);
  --prizm-v3-background-fill-blanket: #0f11157f;
  --prizm-v3-background-fill-reverse: var(--prizm-v3-palette-gray-1100);
  --prizm-v3-background-stroke: var(--prizm-v3-palette-gray-200);
  --prizm-v3-background-stroke-focus: var(--prizm-v3-palette-blue-400);
  --prizm-v3-table-fill-header-default: var(--prizm-v3-palette-gray-100);
  --prizm-v3-table-fill-header-hover: var(--prizm-v3-palette-gray-h150);
  --prizm-v3-table-fill-row-zebra_default: var(--prizm-v3-palette-gray-100);
  --prizm-v3-table-fill-row-hover: var(--prizm-v3-palette-gray-h150);
  --prizm-v3-table-fill-row-active: var(--prizm-v3-palette-blue-200);
  --prizm-v3-table-fill-cell-disable: var(--prizm-v3-palette-gray-200);
  --prizm-v3-table-stroke-cell-default: var(--prizm-v3-palette-gray-200);
  --prizm-v3-table-stroke-cell-hover: var(--prizm-v3-palette-gray-h450);
  --prizm-v3-table-stroke-cell-active: var(--prizm-v3-palette-blue-600);
  --prizm-v3-status-info-primary-default: var(--prizm-v3-palette-blue-600);
  --prizm-v3-status-info-primary-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-status-info-secondary-default: var(--prizm-v3-palette-blue-200);
  --prizm-v3-status-info-secondary-hover: var(--prizm-v3-palette-blue-h250);
  --prizm-v3-status-none-primary-default: var(--prizm-v3-palette-gray-600);
  --prizm-v3-status-none-primary-hover: var(--prizm-v3-palette-gray-h650);
  --prizm-v3-status-none-secondary-default: var(--prizm-v3-palette-gray-200);
  --prizm-v3-status-none-secondary-hover: var(--prizm-v3-palette-gray-h250);
  --prizm-v3-status-success-primary-default: var(--prizm-v3-palette-green-600);
  --prizm-v3-status-success-primary-hover: var(--prizm-v3-palette-green-h650);
  --prizm-v3-status-success-secondary-default: var(--prizm-v3-palette-green-200);
  --prizm-v3-status-success-secondary-hover: var(--prizm-v3-palette-green-h250);
  --prizm-v3-status-attention-primary-default: var(--prizm-v3-palette-yellow-300);
  --prizm-v3-status-attention-primary-hover: var(--prizm-v3-palette-yellow-h350);
  --prizm-v3-status-attention-secondary-default: var(--prizm-v3-palette-yellow-100);
  --prizm-v3-status-attention-secondary-hover: var(--prizm-v3-palette-yellow-h150);
  --prizm-v3-status-warning-primary-default: var(--prizm-v3-palette-orange-400);
  --prizm-v3-status-warning-primary-hover: var(--prizm-v3-palette-orange-h450);
  --prizm-v3-status-warning-secondary-default: var(--prizm-v3-palette-orange-100);
  --prizm-v3-status-warning-secondary-hover: var(--prizm-v3-palette-orange-h150);
  --prizm-v3-status-alarm-primary-default: var(--prizm-v3-palette-red-600);
  --prizm-v3-status-alarm-primary-hover: var(--prizm-v3-palette-red-h650);
  --prizm-v3-status-alarm-secondary-default: var(--prizm-v3-palette-red-200);
  --prizm-v3-status-alarm-secondary-hover: var(--prizm-v3-palette-red-h250);
  --prizm-v3-index-plan: #0033ff;
  --prizm-v3-index-fact: #20222b;
  --prizm-v3-index-prognosis: var(--prizm-v3-palette-gray-600);
  --prizm-v3-button-primary-solid-default: var(--prizm-v3-palette-blue-600);
  --prizm-v3-button-primary-solid-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-button-primary-solid-active: var(--prizm-v3-palette-blue-700);
  --prizm-v3-button-primary-ghost-active: var(--prizm-v3-palette-blue-200);
  --prizm-v3-button-secondary-solid-default: var(--prizm-v3-palette-gray-600);
  --prizm-v3-button-secondary-solid-hover: var(--prizm-v3-palette-gray-h650);
  --prizm-v3-button-secondary-solid-active: var(--prizm-v3-palette-gray-700);
  --prizm-v3-button-secondary-ghost-active: var(--prizm-v3-palette-gray-200);
  --prizm-v3-button-success-solid-default: var(--prizm-v3-palette-green-600);
  --prizm-v3-button-success-solid-hover: var(--prizm-v3-palette-green-h650);
  --prizm-v3-button-success-solid-active: var(--prizm-v3-palette-green-700);
  --prizm-v3-button-success-ghost-active: var(--prizm-v3-palette-green-200);
  --prizm-v3-button-warning-solid-default: var(--prizm-v3-palette-orange-400);
  --prizm-v3-button-warning-solid-hover: var(--prizm-v3-palette-orange-h450);
  --prizm-v3-button-warning-solid-active: var(--prizm-v3-palette-orange-500);
  --prizm-v3-button-warning-ghost-active: var(--prizm-v3-palette-orange-200);
  --prizm-v3-button-alarm-solid-default: var(--prizm-v3-palette-red-600);
  --prizm-v3-button-alarm-solid-hover: var(--prizm-v3-palette-red-h650);
  --prizm-v3-button-alarm-solid-active: var(--prizm-v3-palette-red-700);
  --prizm-v3-button-alarm-ghost-active: var(--prizm-v3-palette-red-200);
  --prizm-v3-button-ghost-hover: var(--prizm-v3-palette-gray-h150);
  --prizm-v3-button-disable: var(--prizm-v3-palette-gray-100);
  --prizm-v3-form-fill-default: var(--prizm-v3-palette-gray-100);
  --prizm-v3-form-fill-disable: var(--prizm-v3-palette-gray-100);
  --prizm-v3-form-stroke-default: var(--prizm-v3-palette-gray-300);
  --prizm-v3-form-stroke-hover: var(--prizm-v3-palette-gray-h450);
  --prizm-v3-form-stroke-disable: var(--prizm-v3-palette-gray-100);
  --prizm-v3-form-active: var(--prizm-v3-palette-blue-600);
  --prizm-v3-form-active-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-form-active-disable: var(--prizm-v3-palette-blue-400);
  --prizm-v3-chart-axis-default: var(--prizm-v3-palette-gray-300);
  --prizm-v3-chart-axis-line-hover: var(--prizm-v3-palette-1200);
  --prizm-v3-chart-axis-line-active: var(--prizm-v3-palette-blue-600);
  --prizm-v3-chart-axis-area-hover: #8a91a233;
  --prizm-v3-chart-axis-area-active: #436cee33;
  --prizm-v3-chart-fill-blanket: #8a91a233;
  --prizm-v3-shadow-color: var(--prizm-v3-palette-shadow-light);
  --prizm-v3-shadow-big-top: 0px -8px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-big-right: 8px 0px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-big-left: -8px 0px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-big-bottom: 0px 8px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-top: 0px -2px 4px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-right: 2px 0px 4px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-left: -2px 0px 4px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-bottom: 0px 2px 4px 0px var(--prizm-v3-shadow-color);
}
*[data-prizm-theme='dark'] {
  --prizm-v3-shadow-color: var(--prizm-v3-shadow-color-dark);
  --prizm-v3-palette-0: var(--prizm-v3-palette-0-dark);
  --prizm-v3-palette-1200: var(--prizm-v3-palette-1200-dark);
  --prizm-v3-palette-gray-h50: var(--prizm-v3-palette-gray-h50-dark);
  --prizm-v3-palette-gray-100: var(--prizm-v3-palette-gray-100-dark);
  --prizm-v3-palette-gray-h150: var(--prizm-v3-palette-gray-h150-dark);
  --prizm-v3-palette-gray-200: var(--prizm-v3-palette-gray-200-dark);
  --prizm-v3-palette-gray-h250: var(--prizm-v3-palette-gray-h250-dark);
  --prizm-v3-palette-gray-300: var(--prizm-v3-palette-gray-300-dark);
  --prizm-v3-palette-gray-h350: var(--prizm-v3-palette-gray-h350-dark);
  --prizm-v3-palette-gray-400: var(--prizm-v3-palette-gray-400-dark);
  --prizm-v3-palette-gray-h450: var(--prizm-v3-palette-gray-h450-dark);
  --prizm-v3-palette-gray-500: var(--prizm-v3-palette-gray-500-dark);
  --prizm-v3-palette-gray-h550: var(--prizm-v3-palette-gray-h550-dark);
  --prizm-v3-palette-gray-600: var(--prizm-v3-palette-gray-600-dark);
  --prizm-v3-palette-gray-h650: var(--prizm-v3-palette-gray-h650-dark);
  --prizm-v3-palette-gray-700: var(--prizm-v3-palette-gray-700-dark);
  --prizm-v3-palette-gray-h750: var(--prizm-v3-palette-gray-h750-dark);
  --prizm-v3-palette-gray-800: var(--prizm-v3-palette-gray-800-dark);
  --prizm-v3-palette-gray-h850: var(--prizm-v3-palette-gray-h850-dark);
  --prizm-v3-palette-gray-900: var(--prizm-v3-palette-gray-900-dark);
  --prizm-v3-palette-gray-h950: var(--prizm-v3-palette-gray-h950-dark);
  --prizm-v3-palette-gray-1000: var(--prizm-v3-palette-gray-1000-dark);
  --prizm-v3-palette-gray-h1050: var(--prizm-v3-palette-gray-h1050-dark);
  --prizm-v3-palette-gray-1100: var(--prizm-v3-palette-gray-1100-dark);
  --prizm-v3-palette-gray-h1150: var(--prizm-v3-palette-gray-h1150-dark);
  --prizm-v3-palette-blue-h50: var(--prizm-v3-palette-blue-h50-dark);
  --prizm-v3-palette-blue-100: var(--prizm-v3-palette-blue-100-dark);
  --prizm-v3-palette-blue-h150: var(--prizm-v3-palette-blue-h150-dark);
  --prizm-v3-palette-blue-200: var(--prizm-v3-palette-blue-200-dark);
  --prizm-v3-palette-blue-h250: var(--prizm-v3-palette-blue-h250-dark);
  --prizm-v3-palette-blue-300: var(--prizm-v3-palette-blue-300-dark);
  --prizm-v3-palette-blue-h350: var(--prizm-v3-palette-blue-h350-dark);
  --prizm-v3-palette-blue-400: var(--prizm-v3-palette-blue-400-dark);
  --prizm-v3-palette-blue-h450: var(--prizm-v3-palette-blue-h450-dark);
  --prizm-v3-palette-blue-500: var(--prizm-v3-palette-blue-500-dark);
  --prizm-v3-palette-blue-h550: var(--prizm-v3-palette-blue-h550-dark);
  --prizm-v3-palette-blue-600: var(--prizm-v3-palette-blue-600-dark);
  --prizm-v3-palette-blue-h650: var(--prizm-v3-palette-blue-h650-dark);
  --prizm-v3-palette-blue-700: var(--prizm-v3-palette-blue-700-dark);
  --prizm-v3-palette-blue-h750: var(--prizm-v3-palette-blue-h750-dark);
  --prizm-v3-palette-blue-800: var(--prizm-v3-palette-blue-800-dark);
  --prizm-v3-palette-blue-h850: var(--prizm-v3-palette-blue-h850-dark);
  --prizm-v3-palette-blue-900: var(--prizm-v3-palette-blue-900-dark);
  --prizm-v3-palette-blue-h950: var(--prizm-v3-palette-blue-h950-dark);
  --prizm-v3-palette-blue-1000: var(--prizm-v3-palette-blue-1000-dark);
  --prizm-v3-palette-blue-h1050: var(--prizm-v3-palette-blue-h1050-dark);
  --prizm-v3-palette-blue-1100: var(--prizm-v3-palette-blue-1100-dark);
  --prizm-v3-palette-blue-h1150: var(--prizm-v3-palette-blue-h1150-dark);
  --prizm-v3-palette-green-h50: var(--prizm-v3-palette-green-h50-dark);
  --prizm-v3-palette-green-100: var(--prizm-v3-palette-green-100-dark);
  --prizm-v3-palette-green-h150: var(--prizm-v3-palette-green-h150-dark);
  --prizm-v3-palette-green-200: var(--prizm-v3-palette-green-200-dark);
  --prizm-v3-palette-green-h250: var(--prizm-v3-palette-green-h250-dark);
  --prizm-v3-palette-green-300: var(--prizm-v3-palette-green-300-dark);
  --prizm-v3-palette-green-h350: var(--prizm-v3-palette-green-h350-dark);
  --prizm-v3-palette-green-400: var(--prizm-v3-palette-green-400-dark);
  --prizm-v3-palette-green-h450: var(--prizm-v3-palette-green-h450-dark);
  --prizm-v3-palette-green-500: var(--prizm-v3-palette-green-500-dark);
  --prizm-v3-palette-green-h550: var(--prizm-v3-palette-green-h550-dark);
  --prizm-v3-palette-green-600: var(--prizm-v3-palette-green-600-dark);
  --prizm-v3-palette-green-h650: var(--prizm-v3-palette-green-h650-dark);
  --prizm-v3-palette-green-700: var(--prizm-v3-palette-green-700-dark);
  --prizm-v3-palette-green-h750: var(--prizm-v3-palette-green-h750-dark);
  --prizm-v3-palette-green-800: var(--prizm-v3-palette-green-800-dark);
  --prizm-v3-palette-green-h850: var(--prizm-v3-palette-green-h850-dark);
  --prizm-v3-palette-green-900: var(--prizm-v3-palette-green-900-dark);
  --prizm-v3-palette-green-h950: var(--prizm-v3-palette-green-h950-dark);
  --prizm-v3-palette-green-1000: var(--prizm-v3-palette-green-1000-dark);
  --prizm-v3-palette-green-h1050: var(--prizm-v3-palette-green-h1050-dark);
  --prizm-v3-palette-green-1100: var(--prizm-v3-palette-green-1100-dark);
  --prizm-v3-palette-green-h1150: var(--prizm-v3-palette-green-h1150-dark);
  --prizm-v3-palette-yellow-h50: var(--prizm-v3-palette-yellow-h50-dark);
  --prizm-v3-palette-yellow-100: var(--prizm-v3-palette-yellow-100-dark);
  --prizm-v3-palette-yellow-h150: var(--prizm-v3-palette-yellow-h150-dark);
  --prizm-v3-palette-yellow-200: var(--prizm-v3-palette-yellow-200-dark);
  --prizm-v3-palette-yellow-h250: var(--prizm-v3-palette-yellow-h250-dark);
  --prizm-v3-palette-yellow-300: var(--prizm-v3-palette-yellow-300-dark);
  --prizm-v3-palette-yellow-h350: var(--prizm-v3-palette-yellow-h350-dark);
  --prizm-v3-palette-yellow-400: var(--prizm-v3-palette-yellow-400-dark);
  --prizm-v3-palette-yellow-h450: var(--prizm-v3-palette-yellow-h450-dark);
  --prizm-v3-palette-yellow-500: var(--prizm-v3-palette-yellow-500-dark);
  --prizm-v3-palette-yellow-h550: var(--prizm-v3-palette-yellow-h550-dark);
  --prizm-v3-palette-yellow-600: var(--prizm-v3-palette-yellow-600-dark);
  --prizm-v3-palette-yellow-h650: var(--prizm-v3-palette-yellow-h650-dark);
  --prizm-v3-palette-yellow-700: var(--prizm-v3-palette-yellow-700-dark);
  --prizm-v3-palette-yellow-h750: var(--prizm-v3-palette-yellow-h750-dark);
  --prizm-v3-palette-yellow-800: var(--prizm-v3-palette-yellow-800-dark);
  --prizm-v3-palette-yellow-h850: var(--prizm-v3-palette-yellow-h850-dark);
  --prizm-v3-palette-yellow-900: var(--prizm-v3-palette-yellow-900-dark);
  --prizm-v3-palette-yellow-h950: var(--prizm-v3-palette-yellow-h950-dark);
  --prizm-v3-palette-yellow-1000: var(--prizm-v3-palette-yellow-1000-dark);
  --prizm-v3-palette-yellow-h1050: var(--prizm-v3-palette-yellow-h1050-dark);
  --prizm-v3-palette-yellow-1100: var(--prizm-v3-palette-yellow-1100-dark);
  --prizm-v3-palette-yellow-h1150: var(--prizm-v3-palette-yellow-h1150-dark);
  --prizm-v3-palette-orange-h50: var(--prizm-v3-palette-orange-h50-dark);
  --prizm-v3-palette-orange-100: var(--prizm-v3-palette-orange-100-dark);
  --prizm-v3-palette-orange-h150: var(--prizm-v3-palette-orange-h150-dark);
  --prizm-v3-palette-orange-200: var(--prizm-v3-palette-orange-200-dark);
  --prizm-v3-palette-orange-h250: var(--prizm-v3-palette-orange-h250-dark);
  --prizm-v3-palette-orange-300: var(--prizm-v3-palette-orange-300-dark);
  --prizm-v3-palette-orange-h350: var(--prizm-v3-palette-orange-h350-dark);
  --prizm-v3-palette-orange-400: var(--prizm-v3-palette-orange-400-dark);
  --prizm-v3-palette-orange-h450: var(--prizm-v3-palette-orange-h450-dark);
  --prizm-v3-palette-orange-500: var(--prizm-v3-palette-orange-500-dark);
  --prizm-v3-palette-orange-h550: var(--prizm-v3-palette-orange-h550-dark);
  --prizm-v3-palette-orange-600: var(--prizm-v3-palette-orange-600-dark);
  --prizm-v3-palette-orange-h650: var(--prizm-v3-palette-orange-h650-dark);
  --prizm-v3-palette-orange-700: var(--prizm-v3-palette-orange-700-dark);
  --prizm-v3-palette-orange-h750: var(--prizm-v3-palette-orange-h750-dark);
  --prizm-v3-palette-orange-800: var(--prizm-v3-palette-orange-800-dark);
  --prizm-v3-palette-orange-h850: var(--prizm-v3-palette-orange-h850-dark);
  --prizm-v3-palette-orange-900: var(--prizm-v3-palette-orange-900-dark);
  --prizm-v3-palette-orange-h950: var(--prizm-v3-palette-orange-h950-dark);
  --prizm-v3-palette-orange-1000: var(--prizm-v3-palette-orange-1000-dark);
  --prizm-v3-palette-orange-h1050: var(--prizm-v3-palette-orange-h1050-dark);
  --prizm-v3-palette-orange-1100: var(--prizm-v3-palette-orange-1100-dark);
  --prizm-v3-palette-orange-h1150: var(--prizm-v3-palette-orange-h1150-dark);
  --prizm-v3-palette-red-h50: var(--prizm-v3-palette-red-h50-dark);
  --prizm-v3-palette-red-100: var(--prizm-v3-palette-red-100-dark);
  --prizm-v3-palette-red-h150: var(--prizm-v3-palette-red-h150-dark);
  --prizm-v3-palette-red-200: var(--prizm-v3-palette-red-200-dark);
  --prizm-v3-palette-red-h250: var(--prizm-v3-palette-red-h250-dark);
  --prizm-v3-palette-red-300: var(--prizm-v3-palette-red-300-dark);
  --prizm-v3-palette-red-h350: var(--prizm-v3-palette-red-h350-dark);
  --prizm-v3-palette-red-400: var(--prizm-v3-palette-red-400-dark);
  --prizm-v3-palette-red-h450: var(--prizm-v3-palette-red-h450-dark);
  --prizm-v3-palette-red-500: var(--prizm-v3-palette-red-500-dark);
  --prizm-v3-palette-red-h550: var(--prizm-v3-palette-red-h550-dark);
  --prizm-v3-palette-red-600: var(--prizm-v3-palette-red-600-dark);
  --prizm-v3-palette-red-h650: var(--prizm-v3-palette-red-h650-dark);
  --prizm-v3-palette-red-700: var(--prizm-v3-palette-red-700-dark);
  --prizm-v3-palette-red-h750: var(--prizm-v3-palette-red-h750-dark);
  --prizm-v3-palette-red-800: var(--prizm-v3-palette-red-800-dark);
  --prizm-v3-palette-red-h850: var(--prizm-v3-palette-red-h850-dark);
  --prizm-v3-palette-red-900: var(--prizm-v3-palette-red-900-dark);
  --prizm-v3-palette-red-h950: var(--prizm-v3-palette-red-h950-dark);
  --prizm-v3-palette-red-1000: var(--prizm-v3-palette-red-1000-dark);
  --prizm-v3-palette-red-h1050: var(--prizm-v3-palette-red-h1050-dark);
  --prizm-v3-palette-red-1050: var(--prizm-v3-palette-red-1050-dark);
  --prizm-v3-palette-red-h1150: var(--prizm-v3-palette-red-h1150-dark);
  --prizm-v3-text-icon-primary: var(--prizm-v3-palette-1200);
  --prizm-v3-text-icon-secondary: var(--prizm-v3-palette-gray-800);
  --prizm-v3-text-icon-tertiary: var(--prizm-v3-palette-gray-600);
  --prizm-v3-text-icon-disable: var(--prizm-v3-palette-gray-500);
  --prizm-v3-text-icon-link: var(--prizm-v3-palette-blue-500);
  --prizm-v3-text-icon-link-hover: var(--prizm-v3-palette-blue-h750);
  --prizm-v3-text-icon-link-visited: var(--prizm-v3-palette-blue-700);
  --prizm-v3-text-icon-reverse: var(--prizm-v3-palette-0);
  --prizm-v3-text-icon-exception: var(--prizm-v3-palette-1200);
  --prizm-v3-background-fill-primary: var(--prizm-v3-palette-gray-100);
  --prizm-v3-background-fill-secondary: var(--prizm-v3-palette-0);
  --prizm-v3-background-fill-panel: var(--prizm-v3-palette-gray-300);
  --prizm-v3-background-fill-overlay: var(--prizm-v3-palette-gray-200);
  --prizm-v3-background-fill-blanket: #0f11157f;
  --prizm-v3-background-fill-reverse: var(--prizm-v3-palette-gray-1100);
  --prizm-v3-background-stroke: var(--prizm-v3-palette-gray-400);
  --prizm-v3-background-stroke-focus: var(--prizm-v3-palette-blue-800);
  --prizm-v3-table-fill-header-default: var(--prizm-v3-palette-gray-200);
  --prizm-v3-table-fill-header-hover: var(--prizm-v3-palette-gray-h250);
  --prizm-v3-table-fill-row-zebra_default: var(--prizm-v3-palette-gray-200);
  --prizm-v3-table-fill-row-hover: var(--prizm-v3-palette-gray-h250);
  --prizm-v3-table-fill-row-active: var(--prizm-v3-palette-blue-400);
  --prizm-v3-table-fill-cell-disable: var(--prizm-v3-palette-gray-300);
  --prizm-v3-table-stroke-cell-default: var(--prizm-v3-palette-gray-400);
  --prizm-v3-table-stroke-cell-hover: var(--prizm-v3-palette-gray-h650);
  --prizm-v3-table-stroke-cell-active: var(--prizm-v3-palette-blue-600);
  --prizm-v3-status-info-primary-default: var(--prizm-v3-palette-blue-600);
  --prizm-v3-status-info-primary-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-status-info-secondary-default: var(--prizm-v3-palette-blue-300);
  --prizm-v3-status-info-secondary-hover: var(--prizm-v3-palette-blue-h350);
  --prizm-v3-status-none-primary-default: var(--prizm-v3-palette-gray-700);
  --prizm-v3-status-none-primary-hover: var(--prizm-v3-palette-gray-h650);
  --prizm-v3-status-none-secondary-default: var(--prizm-v3-palette-gray-400);
  --prizm-v3-status-none-secondary-hover: var(--prizm-v3-palette-gray-h350);
  --prizm-v3-status-success-primary-default: var(--prizm-v3-palette-green-600);
  --prizm-v3-status-success-primary-hover: var(--prizm-v3-palette-green-h650);
  --prizm-v3-status-success-secondary-default: var(--prizm-v3-palette-green-300);
  --prizm-v3-status-success-secondary-hover: var(--prizm-v3-palette-green-h350);
  --prizm-v3-status-attention-primary-default: var(--prizm-v3-palette-yellow-900);
  --prizm-v3-status-attention-primary-hover: var(--prizm-v3-palette-yellow-h950);
  --prizm-v3-status-attention-secondary-default: var(--prizm-v3-palette-yellow-300);
  --prizm-v3-status-attention-secondary-hover: var(--prizm-v3-palette-yellow-h350);
  --prizm-v3-status-warning-primary-default: var(--prizm-v3-palette-orange-800);
  --prizm-v3-status-warning-primary-hover: var(--prizm-v3-palette-orange-h850);
  --prizm-v3-status-warning-secondary-default: var(--prizm-v3-palette-orange-300);
  --prizm-v3-status-warning-secondary-hover: var(--prizm-v3-palette-orange-h350);
  --prizm-v3-status-alarm-primary-default: var(--prizm-v3-palette-red-600);
  --prizm-v3-status-alarm-primary-hover: var(--prizm-v3-palette-red-h650);
  --prizm-v3-status-alarm-secondary-default: var(--prizm-v3-palette-red-300);
  --prizm-v3-status-alarm-secondary-hover: var(--prizm-v3-palette-red-h350);
  --prizm-v3-index-plan: #4075ff;
  --prizm-v3-index-fact: #ffffff;
  --prizm-v3-index-prognosis: var(--prizm-v3-palette-gray-600);
  --prizm-v3-button-primary-solid-default: var(--prizm-v3-palette-blue-600);
  --prizm-v3-button-primary-solid-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-button-primary-solid-active: var(--prizm-v3-palette-blue-700);
  --prizm-v3-button-primary-ghost-active: var(--prizm-v3-palette-blue-400);
  --prizm-v3-button-secondary-solid-default: var(--prizm-v3-palette-gray-600);
  --prizm-v3-button-secondary-solid-hover: var(--prizm-v3-palette-gray-h650);
  --prizm-v3-button-secondary-solid-active: var(--prizm-v3-palette-gray-700);
  --prizm-v3-button-secondary-ghost-active: var(--prizm-v3-palette-gray-400);
  --prizm-v3-button-success-solid-default: var(--prizm-v3-palette-green-600);
  --prizm-v3-button-success-solid-hover: var(--prizm-v3-palette-green-h650);
  --prizm-v3-button-success-solid-active: var(--prizm-v3-palette-green-700);
  --prizm-v3-button-success-ghost-active: var(--prizm-v3-palette-green-400);
  --prizm-v3-button-warning-solid-default: var(--prizm-v3-palette-orange-800);
  --prizm-v3-button-warning-solid-hover: var(--prizm-v3-palette-orange-h850);
  --prizm-v3-button-warning-solid-active: var(--prizm-v3-palette-orange-900);
  --prizm-v3-button-warning-ghost-active: var(--prizm-v3-palette-orange-400);
  --prizm-v3-button-alarm-solid-default: var(--prizm-v3-palette-red-600);
  --prizm-v3-button-alarm-solid-hover: var(--prizm-v3-palette-red-h650);
  --prizm-v3-button-alarm-solid-active: var(--prizm-v3-palette-red-700);
  --prizm-v3-button-alarm-ghost-active: var(--prizm-v3-palette-red-400);
  --prizm-v3-button-ghost-hover: var(--prizm-v3-palette-gray-h350);
  --prizm-v3-button-disable: var(--prizm-v3-palette-gray-300);
  --prizm-v3-form-fill-default: var(--prizm-v3-palette-0);
  --prizm-v3-form-fill-disable: var(--prizm-v3-palette-0);
  --prizm-v3-form-stroke-default: var(--prizm-v3-palette-gray-500);
  --prizm-v3-form-stroke-hover: var(--prizm-v3-palette-gray-h650);
  --prizm-v3-form-stroke-disable: var(--prizm-v3-palette-1200);
  --prizm-v3-form-active: var(--prizm-v3-palette-blue-600);
  --prizm-v3-form-active-hover: var(--prizm-v3-palette-blue-h650);
  --prizm-v3-form-active-disable: var(--prizm-v3-palette-blue-400);
  --prizm-v3-chart-axis-default: var(--prizm-v3-palette-gray-300);
  --prizm-v3-chart-axis-line-hover: var(--prizm-v3-palette-1200);
  --prizm-v3-chart-axis-line-active: var(--prizm-v3-palette-blue-600);
  --prizm-v3-chart-axis-area-hover: #8a91a233;
  --prizm-v3-chart-axis-area-active: #436cee33;
  --prizm-v3-chart-fill-blanket: #8a91a233;
  --prizm-v3-shadow-color: var(--prizm-v3-palette-shadow-dark);
  --prizm-v3-shadow-big-top: 0px -8px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-big-right: 8px 0px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-big-left: -8px 0px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-big-bottom: 0px 8px 32px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-top: 0px -2px 4px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-right: 2px 0px 4px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-left: -2px 0px 4px 0px var(--prizm-v3-shadow-color);
  --prizm-v3-shadow-mini-bottom: 0px 2px 4px 0px var(--prizm-v3-shadow-color);
}
* {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('fonts/Inter-Thin-BETA.woff2') format('woff2'), url('fonts/Inter-Thin-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  src: url('fonts/Inter-ThinItalic-BETA.woff2') format('woff2'), url('fonts/Inter-ThinItalic-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('fonts/Inter-ExtraLight-BETA.woff2') format('woff2'), url('fonts/Inter-ExtraLight-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  src: url('fonts/Inter-ExtraLightItalic-BETA.woff2') format('woff2'), url('fonts/Inter-ExtraLightItalic-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/Inter-Light-BETA.woff2') format('woff2'), url('fonts/Inter-Light-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/Inter-LightItalic-BETA.woff2') format('woff2'), url('fonts/Inter-LightItalic-BETA.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Inter-Regular.woff2') format('woff2'), url('fonts/Inter-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/Inter-Italic.woff2') format('woff2'), url('fonts/Inter-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Inter-Medium.woff2') format('woff2'), url('fonts/Inter-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/Inter-MediumItalic.woff2') format('woff2'), url('fonts/Inter-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/Inter-SemiBold.woff2') format('woff2'), url('fonts/Inter-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/Inter-SemiBoldItalic.woff2') format('woff2'), url('fonts/Inter-SemiBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/Inter-Bold.woff2') format('woff2'), url('fonts/Inter-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/Inter-BoldItalic.woff2') format('woff2'), url('fonts/Inter-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/Inter-ExtraBold.woff2') format('woff2'), url('fonts/Inter-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/Inter-ExtraBoldItalic.woff2') format('woff2'), url('fonts/Inter-ExtraBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/Inter-Black.woff2') format('woff2'), url('fonts/Inter-Black.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  src: url('fonts/Inter-BlackItalic.woff2') format('woff2'), url('fonts/Inter-BlackItalic.woff') format('woff');
}
/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('fonts/Inter-upright.var.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('fonts/Inter-italic.var.woff2') format('woff2');
}
/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  src: url('fonts/Inter.var.woff2') format('woff2');
}
