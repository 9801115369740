.prizmV3ThemeDefaultPalette() {
  --prizm-v3-palette-0: var(--prizm-v3-palette-0-light);
  --prizm-v3-palette-1200: var(--prizm-v3-palette-1200-light);
  --prizm-v3-palette-gray-h50: var(--prizm-v3-palette-gray-h50-light);
  --prizm-v3-palette-gray-100: var(--prizm-v3-palette-gray-100-light);
  --prizm-v3-palette-gray-h150: var(--prizm-v3-palette-gray-h150-light);
  --prizm-v3-palette-gray-200: var(--prizm-v3-palette-gray-200-light);
  --prizm-v3-palette-gray-h250: var(--prizm-v3-palette-gray-h250-light);
  --prizm-v3-palette-gray-300: var(--prizm-v3-palette-gray-300-light);
  --prizm-v3-palette-gray-h350: var(--prizm-v3-palette-gray-h350-light);
  --prizm-v3-palette-gray-400: var(--prizm-v3-palette-gray-400-light);
  --prizm-v3-palette-gray-h450: var(--prizm-v3-palette-gray-h450-light);
  --prizm-v3-palette-gray-500: var(--prizm-v3-palette-gray-500-light);
  --prizm-v3-palette-gray-h550: var(--prizm-v3-palette-gray-h550-light);
  --prizm-v3-palette-gray-600: var(--prizm-v3-palette-gray-600-light);
  --prizm-v3-palette-gray-h650: var(--prizm-v3-palette-gray-h650-light);
  --prizm-v3-palette-gray-700: var(--prizm-v3-palette-gray-700-light);
  --prizm-v3-palette-gray-h750: var(--prizm-v3-palette-gray-h750-light);
  --prizm-v3-palette-gray-800: var(--prizm-v3-palette-gray-800-light);
  --prizm-v3-palette-gray-h850: var(--prizm-v3-palette-gray-h850-light);
  --prizm-v3-palette-gray-900: var(--prizm-v3-palette-gray-900-light);
  --prizm-v3-palette-gray-h950: var(--prizm-v3-palette-gray-h950-light);
  --prizm-v3-palette-gray-1000: var(--prizm-v3-palette-gray-1000-light);
  --prizm-v3-palette-gray-h1050: var(--prizm-v3-palette-gray-h1050-light);
  --prizm-v3-palette-gray-1100: var(--prizm-v3-palette-gray-1100-light);
  --prizm-v3-palette-gray-h1150: var(--prizm-v3-palette-gray-h1150-light);
  --prizm-v3-palette-blue-h50: var(--prizm-v3-palette-blue-h50-light);
  --prizm-v3-palette-blue-100: var(--prizm-v3-palette-blue-100-light);
  --prizm-v3-palette-blue-h150: var(--prizm-v3-palette-blue-h150-light);
  --prizm-v3-palette-blue-200: var(--prizm-v3-palette-blue-200-light);
  --prizm-v3-palette-blue-h250: var(--prizm-v3-palette-blue-h250-light);
  --prizm-v3-palette-blue-300: var(--prizm-v3-palette-blue-300-light);
  --prizm-v3-palette-blue-h350: var(--prizm-v3-palette-blue-h350-light);
  --prizm-v3-palette-blue-400: var(--prizm-v3-palette-blue-400-light);
  --prizm-v3-palette-blue-h450: var(--prizm-v3-palette-blue-h450-light);
  --prizm-v3-palette-blue-500: var(--prizm-v3-palette-blue-500-light);
  --prizm-v3-palette-blue-h550: var(--prizm-v3-palette-blue-h550-light);
  --prizm-v3-palette-blue-600: var(--prizm-v3-palette-blue-600-light);
  --prizm-v3-palette-blue-h650: var(--prizm-v3-palette-blue-h650-light);
  --prizm-v3-palette-blue-700: var(--prizm-v3-palette-blue-700-light);
  --prizm-v3-palette-blue-h750: var(--prizm-v3-palette-blue-h750-light);
  --prizm-v3-palette-blue-800: var(--prizm-v3-palette-blue-800-light);
  --prizm-v3-palette-blue-h850: var(--prizm-v3-palette-blue-h850-light);
  --prizm-v3-palette-blue-900: var(--prizm-v3-palette-blue-900-light);
  --prizm-v3-palette-blue-h950: var(--prizm-v3-palette-blue-h950-light);
  --prizm-v3-palette-blue-1000: var(--prizm-v3-palette-blue-1000-light);
  --prizm-v3-palette-blue-h1050: var(--prizm-v3-palette-blue-h1050-light);
  --prizm-v3-palette-blue-1100: var(--prizm-v3-palette-blue-1100-light);
  --prizm-v3-palette-blue-h1150: var(--prizm-v3-palette-blue-h1150-light);
  --prizm-v3-palette-green-h50: var(--prizm-v3-palette-green-h50-light);
  --prizm-v3-palette-green-100: var(--prizm-v3-palette-green-100-light);
  --prizm-v3-palette-green-h150: var(--prizm-v3-palette-green-h150-light);
  --prizm-v3-palette-green-200: var(--prizm-v3-palette-green-200-light);
  --prizm-v3-palette-green-h250: var(--prizm-v3-palette-green-h250-light);
  --prizm-v3-palette-green-300: var(--prizm-v3-palette-green-300-light);
  --prizm-v3-palette-green-h350: var(--prizm-v3-palette-green-h350-light);
  --prizm-v3-palette-green-400: var(--prizm-v3-palette-green-400-light);
  --prizm-v3-palette-green-h450: var(--prizm-v3-palette-green-h450-light);
  --prizm-v3-palette-green-500: var(--prizm-v3-palette-green-500-light);
  --prizm-v3-palette-green-h550: var(--prizm-v3-palette-green-h550-light);
  --prizm-v3-palette-green-600: var(--prizm-v3-palette-green-600-light);
  --prizm-v3-palette-green-h650: var(--prizm-v3-palette-green-h650-light);
  --prizm-v3-palette-green-700: var(--prizm-v3-palette-green-700-light);
  --prizm-v3-palette-green-h750: var(--prizm-v3-palette-green-h750-light);
  --prizm-v3-palette-green-800: var(--prizm-v3-palette-green-800-light);
  --prizm-v3-palette-green-h850: var(--prizm-v3-palette-green-h850-light);
  --prizm-v3-palette-green-900: var(--prizm-v3-palette-green-900-light);
  --prizm-v3-palette-green-h950: var(--prizm-v3-palette-green-h950-light);
  --prizm-v3-palette-green-1000: var(--prizm-v3-palette-green-1000-light);
  --prizm-v3-palette-green-h1050: var(--prizm-v3-palette-green-h1050-light);
  --prizm-v3-palette-green-1100: var(--prizm-v3-palette-green-1100-light);
  --prizm-v3-palette-green-h1150: var(--prizm-v3-palette-green-h1150-light);
  --prizm-v3-palette-yellow-h50: var(--prizm-v3-palette-yellow-h50-light);
  --prizm-v3-palette-yellow-100: var(--prizm-v3-palette-yellow-100-light);
  --prizm-v3-palette-yellow-h150: var(--prizm-v3-palette-yellow-h150-light);
  --prizm-v3-palette-yellow-200: var(--prizm-v3-palette-yellow-200-light);
  --prizm-v3-palette-yellow-h250: var(--prizm-v3-palette-yellow-h250-light);
  --prizm-v3-palette-yellow-300: var(--prizm-v3-palette-yellow-300-light);
  --prizm-v3-palette-yellow-h350: var(--prizm-v3-palette-yellow-h350-light);
  --prizm-v3-palette-yellow-400: var(--prizm-v3-palette-yellow-400-light);
  --prizm-v3-palette-yellow-h450: var(--prizm-v3-palette-yellow-h450-light);
  --prizm-v3-palette-yellow-500: var(--prizm-v3-palette-yellow-500-light);
  --prizm-v3-palette-yellow-h550: var(--prizm-v3-palette-yellow-h550-light);
  --prizm-v3-palette-yellow-600: var(--prizm-v3-palette-yellow-600-light);
  --prizm-v3-palette-yellow-h650: var(--prizm-v3-palette-yellow-h650-light);
  --prizm-v3-palette-yellow-700: var(--prizm-v3-palette-yellow-700-light);
  --prizm-v3-palette-yellow-h750: var(--prizm-v3-palette-yellow-h750-light);
  --prizm-v3-palette-yellow-800: var(--prizm-v3-palette-yellow-800-light);
  --prizm-v3-palette-yellow-h850: var(--prizm-v3-palette-yellow-h850-light);
  --prizm-v3-palette-yellow-900: var(--prizm-v3-palette-yellow-900-light);
  --prizm-v3-palette-yellow-h950: var(--prizm-v3-palette-yellow-h950-light);
  --prizm-v3-palette-yellow-1000: var(--prizm-v3-palette-yellow-1000-light);
  --prizm-v3-palette-yellow-h1050: var(--prizm-v3-palette-yellow-h1050-light);
  --prizm-v3-palette-yellow-1100: var(--prizm-v3-palette-yellow-1100-light);
  --prizm-v3-palette-yellow-h1150: var(--prizm-v3-palette-yellow-h1150-light);
  --prizm-v3-palette-orange-h50: var(--prizm-v3-palette-orange-h50-light);
  --prizm-v3-palette-orange-100: var(--prizm-v3-palette-orange-100-light);
  --prizm-v3-palette-orange-h150: var(--prizm-v3-palette-orange-h150-light);
  --prizm-v3-palette-orange-200: var(--prizm-v3-palette-orange-200-light);
  --prizm-v3-palette-orange-h250: var(--prizm-v3-palette-orange-h250-light);
  --prizm-v3-palette-orange-300: var(--prizm-v3-palette-orange-300-light);
  --prizm-v3-palette-orange-h350: var(--prizm-v3-palette-orange-h350-light);
  --prizm-v3-palette-orange-400: var(--prizm-v3-palette-orange-400-light);
  --prizm-v3-palette-orange-h450: var(--prizm-v3-palette-orange-h450-light);
  --prizm-v3-palette-orange-500: var(--prizm-v3-palette-orange-500-light);
  --prizm-v3-palette-orange-h550: var(--prizm-v3-palette-orange-h550-light);
  --prizm-v3-palette-orange-600: var(--prizm-v3-palette-orange-600-light);
  --prizm-v3-palette-orange-h650: var(--prizm-v3-palette-orange-h650-light);
  --prizm-v3-palette-orange-700: var(--prizm-v3-palette-orange-700-light);
  --prizm-v3-palette-orange-h750: var(--prizm-v3-palette-orange-h750-light);
  --prizm-v3-palette-orange-800: var(--prizm-v3-palette-orange-800-light);
  --prizm-v3-palette-orange-h850: var(--prizm-v3-palette-orange-h850-light);
  --prizm-v3-palette-orange-900: var(--prizm-v3-palette-orange-900-light);
  --prizm-v3-palette-orange-h950: var(--prizm-v3-palette-orange-h950-light);
  --prizm-v3-palette-orange-1000: var(--prizm-v3-palette-orange-1000-light);
  --prizm-v3-palette-orange-h1050: var(--prizm-v3-palette-orange-h1050-light);
  --prizm-v3-palette-orange-1100: var(--prizm-v3-palette-orange-1100-light);
  --prizm-v3-palette-orange-h1150: var(--prizm-v3-palette-orange-h1150-light);
  --prizm-v3-palette-red-h50: var(--prizm-v3-palette-red-h50-light);
  --prizm-v3-palette-red-100: var(--prizm-v3-palette-red-100-light);
  --prizm-v3-palette-red-h150: var(--prizm-v3-palette-red-h150-light);
  --prizm-v3-palette-red-200: var(--prizm-v3-palette-red-200-light);
  --prizm-v3-palette-red-h250: var(--prizm-v3-palette-red-h250-light);
  --prizm-v3-palette-red-300: var(--prizm-v3-palette-red-300-light);
  --prizm-v3-palette-red-h350: var(--prizm-v3-palette-red-h350-light);
  --prizm-v3-palette-red-400: var(--prizm-v3-palette-red-400-light);
  --prizm-v3-palette-red-h450: var(--prizm-v3-palette-red-h450-light);
  --prizm-v3-palette-red-500: var(--prizm-v3-palette-red-500-light);
  --prizm-v3-palette-red-h550: var(--prizm-v3-palette-red-h550-light);
  --prizm-v3-palette-red-600: var(--prizm-v3-palette-red-600-light);
  --prizm-v3-palette-red-h650: var(--prizm-v3-palette-red-h650-light);
  --prizm-v3-palette-red-700: var(--prizm-v3-palette-red-700-light);
  --prizm-v3-palette-red-h750: var(--prizm-v3-palette-red-h750-light);
  --prizm-v3-palette-red-800: var(--prizm-v3-palette-red-800-light);
  --prizm-v3-palette-red-h850: var(--prizm-v3-palette-red-h850-light);
  --prizm-v3-palette-red-900: var(--prizm-v3-palette-red-900-light);
  --prizm-v3-palette-red-h950: var(--prizm-v3-palette-red-h950-light);
  --prizm-v3-palette-red-1000: var(--prizm-v3-palette-red-1000-light);
  --prizm-v3-palette-red-h1050: var(--prizm-v3-palette-red-h1050-light);
  --prizm-v3-palette-red-1050: var(--prizm-v3-palette-red-1050-light);
  --prizm-v3-palette-red-h1150: var(--prizm-v3-palette-red-h1150-light);
}
